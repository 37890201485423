import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePageActions } from '@hooks';
import { useTranslation } from 'react-i18next';
import WeblinkWizard from './Wizard';
import { editWeblink, fetchWeblinks, fetchWeblinkLatestVersion } from '@redux/weblinks/actions';
import {
  selectWeblink,
  selectWeblinksEditing,
  selectWeblinkVersion,
} from '@redux/weblinks/selectors';

function EditWeblink({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, weblink] = useSelector(selectWeblink(match.params.weblinkId));
  const [, weblinkVersion] = useSelector(selectWeblinkVersion(match.params.weblinkId));
  const editing = useSelector(selectWeblinksEditing);
  const { setTitle } = usePageActions({});

  useEffect(() => {
    dispatch(fetchWeblinks());
    dispatch(fetchWeblinkLatestVersion(match.params.weblinkId));
  }, [dispatch, match.params.weblinkId]);

  useEffect(() => {
    if (weblink) setTitle(t('weblinks:Wizard.edit', { name: weblink.name }));
  }, [setTitle, t, weblink]);

  const initialData = useMemo(
    () =>
      weblink
        ? {
            content: { url: weblinkVersion?.content_url },
            details: {
              description: weblink.description,
              name: weblink.name,
              status: weblink.is_available ? 'published' : 'unpublished',
              contentType: weblink.type,
              audience: weblink.audience_type,
              langauge: weblink.latest_version?.metadata.langauge || 'en',
            },
          }
        : undefined,
    [weblink, weblinkVersion],
  );

  const onCompleted = useCallback(
    formData => {
      dispatch(
        editWeblink(match.params.weblinkId, {
          contentType: formData.details.contentType,
          description: formData.details.description,
          published: formData.details.status,
          name: formData.details.name,
          url: formData.content.url,
          audience: formData.details.audience,
          langugage: formData.details.language,
        }),
      );
    },
    [dispatch, match.params.weblinkId],
  );

  return (
    <WeblinkWizard
      initialData={initialData}
      isEdit
      loading={loading}
      submitting={editing}
      onCompleted={onCompleted}
    />
  );
}

export default EditWeblink;
