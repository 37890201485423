import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { whatLabelTypes, whoTypes, whenTypes, DELAY, FEATURE_WEBLINK } from '@constants';

export default (t, useLatest, title) => [
  {
    title: t('pathways:ProcedurePathways.wizard.stagedRules.columns.name'),
    dataIndex: 'name',
    key: `name`,
    width: 250,
    ellipsis: true,
    sorter: (a, b) => {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      return 0;
    },
  },
  {
    title: t('pathways:ProcedurePathways.wizard.stagedRules.columns.what'),
    dataIndex: 'what',
    key: `what`,
    ellipsis: true,
    width: 150,
    render: (what, rule) => {
      if (what === FEATURE_WEBLINK) {
        const whatType = rule.whatDetail.type || what;
        return t(whatLabelTypes[whatType]);
      }

      return t(whatLabelTypes[what]);
    },
    sorter: (a, b) => {
      if (a.what > b.what) return -1;
      if (a.what < b.what) return 1;
      return 0;
    },
  },
  {
    title: t('pathways:ProcedurePathways.wizard.stagedRules.columns.who'),
    dataIndex: 'who',
    key: `who`,
    ellipsis: true,
    render: who => t(whoTypes[who]),
    sorter: (a, b) => {
      if (a.who > b.who) return -1;
      if (a.who < b.who) return 1;
      return 0;
    },
  },
  {
    title: t('pathways:ProcedurePathways.wizard.stagedRules.columns.when'),
    dataIndex: 'when',
    key: `when`,
    ellipsis: true,
    render: (when, record) => {
      if (when === DELAY) return `${record.whenDetail.days} ${t(whenTypes[when])}`;
      return t(whenTypes[when]);
    },
    sorter: (a, b) => {
      if (a.when > b.when) return -1;
      if (a.when < b.when) return 1;
      return 0;
    },
  },
  // {
  //   title: t('pathways:ProcedurePathways.wizard.stagedRules.columns.version'),
  //   dataIndex: 'id',
  //   key: 'id',
  //   render: (id, record) => {
  //     if (record.id % 2 === 0) return 'Latest';
  //     return (
  //       <Button onClick={() => useLatest(id)}>
  //         {t('pathways:ProcedurePathways.wizard.stagedRules.useLatest')}
  //       </Button>
  //     );
  //   },
  // },
  {
    title: t('common:buttons.delete'),
    dataIndex: 'onDelete',
    key: 'delete',
    width: 150,
    render: onDelete => (
      <Button onClick={onDelete}>
        <FontAwesomeIcon icon={faTrashAlt} /> {t('common:buttons.delete')}
      </Button>
    ),
  },
];
