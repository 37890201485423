import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import {
  faFileAlt,
  faPlayCircle,
  faQuestionCircle,
  faFileEdit,
  faTasks,
  faCommentAltLines,
  faExclamationTriangle,
  faBell,
  faGlobe,
  faLink,
  faEdit,
} from '@fortawesome/pro-regular-svg-icons';

type contentType = {
  label: string;
  icon: IconDefinition;
  path: string;
};

export const DOCUMENT = 'DOCUMENT' as const;
export const VIDEO = 'VIDEO' as const;
export const FORM = 'FORM' as const;
export const QUESTIONNAIRE = 'QUESTIONNAIRE' as const;
export const TASK = 'TASK' as const;
export const WEBLINK = 'WEBLINK' as const;
export const WEBSITE = 'website' as const;
export const WEB_FORM = 'web_form' as const;
export const MESSAGE = 'MESSAGE' as const;
export const ALERT = 'ALERT' as const;
export const REMINDER = 'REMINDER' as const;

export const documentTypes = [DOCUMENT, VIDEO];
export const formTypes = [FORM, QUESTIONNAIRE, TASK];
export const messageTypes = [MESSAGE, ALERT, REMINDER];

export const contentTypesList = [
  ...documentTypes,
  ...formTypes,
  WEBSITE,
  WEB_FORM,
  WEBLINK,
  ...messageTypes,
];

export const contentTypes: { [key: string]: contentType } = {
  DOCUMENT: {
    label: 'common:contentTypes.singular.document',
    icon: faFileAlt,
    path: 'documents',
  },
  VIDEO: {
    label: 'common:contentTypes.singular.video',
    icon: faPlayCircle,
    path: 'videos',
  },
  FORM: {
    label: 'common:contentTypes.singular.form',
    icon: faFileEdit,
    path: 'forms',
  },
  QUESTIONNAIRE: {
    label: 'common:contentTypes.singular.questionnaire',
    icon: faQuestionCircle,
    path: 'questionnaires',
  },
  TASK: {
    label: 'common:contentTypes.singular.task',
    icon: faTasks,
    path: 'tasks',
  },
  WEBLINK: {
    label: 'common:contentTypes.singular.weblink',
    icon: faLink,
    path: 'weblinks',
  },
  website: {
    label: 'common:contentTypes.singular.website',
    icon: faGlobe,
    path: 'weblinks',
  },
  web_form: {
    label: 'common:contentTypes.singular.webform',
    icon: faEdit,
    path: 'weblinks',
  },
  MESSAGE: {
    label: 'common:contentTypes.singular.message',
    icon: faCommentAltLines,
    path: 'messages',
  },
  ALERT: {
    label: 'common:contentTypes.singular.alert',
    icon: faExclamationTriangle,
    path: 'alerts',
  },
  REMINDER: {
    label: 'common:contentTypes.singular.reminder',
    icon: faBell,
    path: 'reminders',
  },
  // ENGAGEMENT: {
  //   label: 'common:contentTypes.singular.engagement',
  //   icon: faRss,
  //   path: 'engagements',
  // },
};

export const getContentTypeValues = (key: string): contentType => contentTypes[key];

export default { contentTypes, getContentTypeValues };
