import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks';
import { Column, ColumnGroup } from '@cards/Card';
import WeblinkDetailsCard from '@cards/Weblinks/Details';
import WeblinkContentCard from '@cards/Weblinks/Details/Content';
import { historyType, matchType } from '../../propTypes';
import { fetchWeblinks } from '@redux/weblinks/actions';
import { selectWeblink } from '@redux/weblinks/selectors';
import EditButton from '@components/EditButton';
import WeblinkPathwaysCard from '@pathways/cards/WeblinkPathways';
import useIsContentOwnedByCurrentOrganisation from '@hooks/useIsContentOwnedByCurrentOrganisation';

function WeblinkDetails({ history, match }) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, weblink] = useSelector(selectWeblink(match.params.weblinkId));

  useEffect(() => {
    dispatch(fetchWeblinks());
  }, [dispatch]);

  const isOwnedContent = useIsContentOwnedByCurrentOrganisation(weblink);

  const { setTitle } = usePageActions({
    actions: [
      <EditButton
        key="edit"
        onClick={() => history.push(`${match.url}/edit`)}
        disabled={!isOwnedContent}
      />,
    ],
    showBack: true,
  });

  useEffect(() => {
    if (weblink) {
      setTitle(weblink.name);
    }
  }, [setTitle, weblink]);

  return (
    <>
      <ColumnGroup>
        <Column>
          <WeblinkDetailsCard weblinkId={match.params.weblinkId} />
          <WeblinkPathwaysCard weblinkId={match.params.weblinkId} />
        </Column>
        <Column>
          <WeblinkContentCard weblinkId={match.params.weblinkId} />
        </Column>
      </ColumnGroup>
    </>
  );
}

WeblinkDetails.propTypes = {
  documentTranslationKey: string,
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default WeblinkDetails;
