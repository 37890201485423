import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { fetchAppUsersPathways } from '@pathways/redux/appUserPathways/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import { ColumnGroup, Column } from '@cards/Card';
import AppUserDetails from '@cards/AppUsers/Details';
import AppUserPathwayStages from '@pathways/cards/AppUsers/PathwayStages';
import ClinicalChecklistCard from '@cards/AppUsers/ClinicalChecklist';
import { matchType } from '../../propTypes';
import { setCurrentScreen, screens } from '../../analytics';

function AppUserOverviewTab({ match }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchAppUsersPathways());
  }, [dispatch]);

  useEffect(() => {
    setCurrentScreen(screens.APP_USER_OVERVIEW);
  }, []);

  const [loading, appUser] = useSelector(selectAppUser(match.params.id));

  return (
    <ColumnGroup>
      <Column>
        <AppUserDetails appUserId={match.params.id} />
        <ClinicalChecklistCard appUser={appUser} />
        {appUser &&
          appUser.pathways &&
          appUser.pathways.map(pathway => (
            <AppUserPathwayStages
              key={pathway.id}
              loading={loading}
              appUserId={match.params.id}
              pathwayId={pathway.originalPathway}
              appUserPathwayId={pathway.id}
            />
          ))}
      </Column>
    </ColumnGroup>
  );
}

AppUserOverviewTab.propTypes = {
  match: matchType.isRequired,
};

export default AppUserOverviewTab;
