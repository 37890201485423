import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import IndexEvents from '@pathways/pages/IndexEvents';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import Hospitals from './Hospitals';
// import HAHospitalDetails from './Hospital';
import HAOrganisationDetails from './CurrentOrganisation';
import HADataRequestList from './DataRequests';
import CreateDataRequest from './DataRequests/CreateDataRequest';
import CreateDeletionRequest from './DataRequests/CreateDeletionRequest';
import DashboardUsers from './DashboardUsers';
import ConnectionCodes from './ConnectionCodes';
import { matchType } from '../../propTypes';

function Administration({ match }) {
  const permissions = useHasPermissions();

  return (
    <Switch>
      {permissions[Permissions.AdministerAllHospitals] ? (
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/organisations`} />}
        />
      ) : (
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/organisation`} />}
        />
      )}
      <Route path={`${match.path}/index-events`} component={IndexEvents} />
      {permissions[Permissions.ManageConnectionCodes] && [
        <Route path={`${match.path}/codes`} key="codes" component={ConnectionCodes} />,
      ]}
      {permissions[Permissions.ViewDashboardUsers] && [
        <Route
          path={`${match.path}/dashboard-users`}
          key="dashboard-users"
          component={DashboardUsers}
        />,
      ]}
      {permissions[Permissions.ViewDataRequests] && [
        <Route
          exact
          path={`${match.path}/create-data-request`}
          key="data-request"
          component={CreateDataRequest}
        />,
        <Route
          exact
          path={`${match.path}/create-deletion-request`}
          key="deletion-request"
          component={CreateDeletionRequest}
        />,
        <Route
          path={`${match.path}/data-requests`}
          key="data-requests-list"
          component={HADataRequestList}
        />,
      ]}
      {permissions[Permissions.AdministerHospital] && [
        <Route
          path={`${match.path}/organisation`}
          key="currentOrg"
          component={HAOrganisationDetails}
        />,
      ]}
      {permissions[Permissions.AdministerAllHospitals] && (
        <Route path={`${match.path}/organisations`} component={Hospitals} />
      )}
    </Switch>
  );
}

Administration.propTypes = {
  match: matchType.isRequired,
};

export default Administration;
