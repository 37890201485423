import React from 'react';
import { bool, func, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import {
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  MESSAGE,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
  OPEN_CONTENT,
  OPEN_LINK,
  FORM_SUBMITTED,
  QUESTIONNAIRE_SUBMITTED,
  TASK_SUBMITTED,
  FEATURE_WEBLINK,
  DOCUMENT_VIEWED,
  VIDEO_VIEWED,
  WEBLINK_VISITED,
  MESSAGE_VIEWED,
  REMINDER_VIEWED,
  ALERT_VIEWED,
} from '@constants';
import FormPreviewModal from '@components/FormPreviewModal';
import DocumentPreview from '@components/DocumentPreview';
import WeblinkPreview from '@components/WeblinkPreview';
import { pathwayRuleType } from '../../../../propTypes';

const documentRuleTypes = [FEATURE_DOCUMENT, FEATURE_VIDEO, DOCUMENT_VIEWED, VIDEO_VIEWED];
const formRuleTypes = [
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  FORM_SUBMITTED,
  QUESTIONNAIRE_SUBMITTED,
  TASK_SUBMITTED,
];
const messageRuleTypes = [
  MESSAGE,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
  MESSAGE_VIEWED,
  REMINDER_VIEWED,
  ALERT_VIEWED,
];
const weblinkRuleTypes = [FEATURE_WEBLINK, WEBLINK_VISITED];

function FormPreview({ form, visible, onCancel }) {
  if (!form) return null;

  return (
    <FormPreviewModal
      readOnly
      name={form.name}
      visible={visible}
      handleCloseModal={onCancel}
      schema={form.latestVersion?.schema ?? {}}
      uiSchema={form.latestVersion?.uiSchema ?? {}}
    />
  );
}

function MessagePreview({ message, visible, onCancel }) {
  const { t } = useTranslation();

  if (!message) return null;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={
        <Button type="primary" onClick={onCancel}>
          {t('common:buttons.close')}
        </Button>
      }
    >
      <div className="notification-preview">
        <div className="notification-message-preview">
          <div className="notification-message-preview-header">
            <span>{t('messages:Wizard.details.preview.title')}</span>
            <span>{t('messages:Wizard.details.preview.now')}</span>
          </div>
          <p>
            <b>{message.title || message.name}</b>
          </p>
          <p>{message.body}</p>
          {message.payload.action === OPEN_LINK && message.payload.data ? (
            <a href={message.payload.data} rel="noopener noreferrer" target="_blank">
              {message.payload.data}
            </a>
          ) : null}
          {message.payload.action === OPEN_CONTENT && message.payload.data.name ? (
            <u>{message.payload.data.name}</u>
          ) : null}
        </div>
      </div>
      <div className="notification-preview-footer">
        {t('messages:Wizard.details.preview.footer')}
      </div>
    </Modal>
  );
}

function ContentPreview({ previewType, rule, visible, onCancel }) {
  if (!rule) return null;

  if (documentRuleTypes.includes(rule[previewType])) {
    return (
      <DocumentPreview
        document={rule[`${previewType}Detail`]}
        visible={visible}
        onCancel={onCancel}
      />
    );
  }

  if (formRuleTypes.includes(rule[previewType])) {
    return (
      <FormPreview form={rule[`${previewType}Detail`]} visible={visible} onCancel={onCancel} />
    );
  }

  if (messageRuleTypes.includes(rule[previewType])) {
    return (
      <MessagePreview
        message={rule[`${previewType}Detail`]}
        visible={visible}
        onCancel={onCancel}
      />
    );
  }

  if (weblinkRuleTypes.includes(rule[previewType])) {
    return (
      <WeblinkPreview
        weblink={rule[`${previewType}Detail`]}
        visible={visible}
        onCancel={onCancel}
      />
    );
  }

  return (
    <Modal visible={visible} onCancel={onCancel}>
      {rule.name}
    </Modal>
  );
}

ContentPreview.propTypes = {
  previewType: oneOf(['what', 'when']),
  rule: pathwayRuleType,
  visible: bool,
  onCancel: func.isRequired,
};

ContentPreview.defaultProps = {
  previewType: 'what',
  rule: undefined,
  visible: false,
};

export default ContentPreview;
