import { call, getContext } from 'redux-saga/effects';
import { IPEPHealthClient } from '@liquid-state/pephealth-client';
import createPEPHealthClient from '@api/pepHealthClient';

function* doCreatePEPHealthClient(): any {
  const tokens = yield getContext('tokens');
  const token = yield call(tokens.get, 'idm');
  const client: IPEPHealthClient = yield call(createPEPHealthClient, token);

  return client;
}

export default doCreatePEPHealthClient;
