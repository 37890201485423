import React from 'react';
import { WEBSITE, WEB_FORM } from '@constants';
import { createLanguageFilters } from '@utils/supportedLanguages';
import LanguageIcon from '@components/LanguageIcon';
import PubslishedStatusIcon from '@components/PublishedStatusIcon';
import WeblinkTypeIcon from '@components/WeblinkTypeIcon';

export default t => [
  {
    className: 'language-column',
    title: t('cards:WeblinkList.columns.language.title'),
    dataIndex: 'latest_version',
    key: 'latest_version',
    render: latest_version => <LanguageIcon language={latest_version?.metadata?.language} />,
    filters: createLanguageFilters(t),
    onFilter: (value, record) => {
      return (record.latest_version?.metadata?.language || 'en').toLowerCase() === value;
    },
    width: 125,
  },
  {
    dataIndex: 'type',
    filters: [
      {
        text: t('common:contentTypes.singular.website'),
        value: WEBSITE,
      },
      {
        text: t('common:contentTypes.singular.webform'),
        value: WEB_FORM,
      },
    ],
    title: t('cards:WeblinkList.columns.type.title'),
    width: 200,
    onFilter: (value, record) => value === record.type,
    render: type => <WeblinkTypeIcon showText type={type} />,
    sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
  },
  {
    dataIndex: 'name',
    title: t('cards:WeblinkList.columns.name.title'),
  },
  {
    dataIndex: 'description',
    title: t('cards:WeblinkList.columns.description'),
  },
  {
    className: 'published-column',
    dataIndex: 'is_available',
    title: t('cards:WeblinkList.columns.published.title'),
    render: published => <PubslishedStatusIcon published={published} />,
    filters: [
      {
        text: t('cards:WeblinkList.columns.published.published'),
        value: true,
      },
      {
        text: t('cards:WeblinkList.columns.published.unpublished'),
        value: false,
      },
    ],
    onFilter: (value, record) => record.is_available === value,
  },
];
