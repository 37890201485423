import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Divider } from 'antd';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import { deleteWeblinks, fetchWeblinks } from '@redux/weblinks/actions';
import { selectWeblinks, selectWeblinksDeleting } from '@redux/weblinks/selectors';
import SearchableTable from '@components/SearchableTable';
import IconButton from '@components/IconButton';
import MessageButton from '@components/MessageButton';
import createColumns from './createColumns';
import './style.less';

const filterFunc = (value, comparator) => comparator(value.name) || comparator(value.description);

function WeblinksListCard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, weblinks] = useSelector(selectWeblinks);
  const isDeleting = useSelector(selectWeblinksDeleting);
  const { t } = useTranslation();
  const columns = useMemo(() => createColumns(t), [t]);
  const [selectedIds, setSelectedIds] = useState([]);
  const { [Permissions.ViewPatients]: canViewPatients } = useHasPermissions(
    Permissions.ViewPatients,
  );

  useEffect(() => {
    dispatch(fetchWeblinks());
  }, [dispatch]);

  return (
    <SearchableTable
      rowKey="uuid"
      columns={columns}
      dataSource={weblinks}
      extra={
        <>
          {canViewPatients ? (
            <>
              <MessageButton
                disabled={selectedIds.length !== 1}
                onClick={() => history.push(`/messages/new/${selectedIds[0]}`)}
              />
              <Divider type="vertical" />
            </>
          ) : null}
          <IconButton
            disabled={!selectedIds.length || isDeleting}
            icon={faTrashAlt}
            tooltip={t('common:buttons.delete')}
            onClick={() => {
              dispatch(deleteWeblinks(selectedIds));
              setSelectedIds([]);
            }}
          />
        </>
      }
      filterFunc={filterFunc}
      loading={loading || isDeleting}
      onRowSelectionChange={selectedIds => setSelectedIds(selectedIds)}
    />
  );
}

export default WeblinksListCard;
