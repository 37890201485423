import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FEATURE_WEBLINK } from '@constants';
import PubslishedStatusIcon from '@components/PublishedStatusIcon';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathwaysByRules } from '@pathways/redux/pathways/reducers';
import { Card } from '../../../cards/Card';
import './style.less';

const pageSize = 10;

const createColumns = t => [
  {
    title: t('cards:DocumentPathways.columns.title'),
    dataIndex: 'name',
    key: 'title',
    sorter: (a, b) => a.name.localeCompare(b.name),
    align: 'left',
  },
  {
    title: t('cards:DocumentPathways.columns.status'),
    dataIndex: 'isActive',
    key: 'status',
    width: 100,
    render: isActive => <PubslishedStatusIcon published={isActive} />,
    sorter: ({ isActive: a }, { isActive: b }) => {
      if (a === b) return 0;
      if (a < b) return 1;
      if (a > b) return -1;
    },
  },
];

const WeblinkPathwaysCard = ({ weblinkId, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const columns = useMemo(() => createColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchPathways());
  }, [dispatch]);

  const [loading, pathways] = useSelector(
    selectPathwaysByRules(
      rule => rule.what === FEATURE_WEBLINK && rule.whatDetail.uuid === weblinkId,
    ),
  );

  return (
    <>
      <Card.Half
        className="weblink-pathways-card"
        title={t('cards:WeblinkPathways.title')}
        noPadding
        loading={loading}
        {...props}
      >
        <Table
          columns={columns}
          dataSource={pathways}
          pagination={{ position: 'bottom', pageSize }}
          rowKey="id"
          onRow={({ id }) => ({
            onClick: () => history.push(`/procedure/pathways/${id}`),
          })}
        />
      </Card.Half>
    </>
  );
};

export default WeblinkPathwaysCard;
