import { ruleWhatToContentRefType } from '@constants';

class ContentRef {
  constructor(
    public organisationId: string,
    public contentType: string,
    public contentId: string,
    public contentVersion: string | undefined = undefined,
  ) {}

  public toString() {
    let ref = `${this.organisationId}/${this.contentType.toLowerCase()}/${this.contentId}/`;
    // This is disabled until the use of version numbers and the Managed Content Update feature are implemented.
    // if (this.contentVersion) {
    //   ref += `${this.contentVersion}`;
    // }
    return ref;
  }

  public static fromString(stringRef: string) {
    const parts = stringRef.split('/');
    const ref = new ContentRef(parts[0], parts[1], parts[2], parts[3]?.toString());
    return ref;
  }

  public static guessContentId(obj: any): string | undefined {
    return obj.content_id || obj.uuid || obj.product_id || obj.id;
  }

  public static fromRuleAndOrgId = (
    rule: { what: string; whatDetail: { [key: string]: any } },
    organisationId: string,
  ): ContentRef => {
    const contentType = ruleWhatToContentRefType(rule.what);
    const contentId = ContentRef.guessContentId(rule.whatDetail);
    // get version number from the latest version for Content
    let contentVersion: number | undefined =
      rule.whatDetail.latestVersion && rule.whatDetail.latestVersion.number;
    if (!contentVersion) {
      // Documents have their propert in snake-case... so cater to that if there is no version number value yet.
      contentVersion = rule.whatDetail.latest_version && rule.whatDetail.latest_version.number;
    }
    const ref = new ContentRef(organisationId, contentType, contentId!, contentVersion?.toString());
    return ref;
  };
}

export default ContentRef;
