import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Modal } from 'antd';
import { faInfoCircle, faMinusCircle } from '@fortawesome/pro-regular-svg-icons';
import { ColumnGroup, Column } from '@cards/Card';
import IconButton from '@components/IconButton';
import SearchableTable from '@components/SearchableTable';
import SelectContentModal from '@components/SelectContentModal';
import {
  fetchOrganisation,
  getOrganisationPublicContent,
  createOrganisationPublicContent,
  removeOrganisationPublicContent,
} from '@redux/organisations/actions';
import {
  selectOrganisation,
  selectOrganisationEditing,
  selectOrganisationPublicContent,
} from '@redux/organisations/reducers';
import { selectContent } from '@components/SelectContentModal';
import {
  documentTypes,
  DOCUMENT,
  VIDEO,
  FORM,
  QUESTIONNAIRE,
  TASK,
  WEBLINK,
  WEBSITE,
  WEB_FORM,
} from '@utils/contentTypes';
import createColumns from './createColumns';
import { setCurrentScreen, screens } from '../../../../analytics';
import './style.less';

const contentTypes = [...documentTypes, WEBSITE, WEB_FORM];

const filterFunc = (value, comparator) => comparator(value.name);

function Content() {
  useEffect(() => {
    setCurrentScreen(screens.ADMINISTRATION_HOSPITAL_CONTENT);
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [rowsToRemove, setRowsToRemove] = useState([]);
  const dashboardUserProfile = useSelector(state => state.login.user.profile);
  const editing = useSelector(selectOrganisationEditing);
  const [loading, organisation] = useSelector(
    selectOrganisation(dashboardUserProfile.organisationId),
  );
  const [maxOrder, setMaxOrder] = useState(0);
  const columns = useMemo(() => createColumns(t, contentTypes, maxOrder), [t, maxOrder]);
  const [contentLoading, contentFromState] = useSelector(selectContent);
  const publicContent = useSelector(selectOrganisationPublicContent);

  const content = useMemo(() => {
    const filteredContentFromState = contentFromState.filter(content => {
      const contentToInclude = publicContent.find(row => {
        if (content.product_id === row.product_id || content.id === row.product_id) {
          return true;
        }
        return false;
      });

      return contentToInclude;
    });

    return filteredContentFromState
      .map(filteredStateContent => {
        const matchingPublicContent = publicContent.find(
          pContent =>
            pContent.product_id === filteredStateContent.product_id ||
            pContent.product_id === filteredStateContent.id,
        );
        return {
          ...filteredStateContent,
          pk: matchingPublicContent.pk,
          order: matchingPublicContent._order,
        };
      })
      .sort((a, b) => a.order - b.order);
  }, [contentFromState, publicContent]);

  useEffect(() => {
    if (publicContent) {
      const maxOrder = publicContent.reduce((acc, curr) => {
        if (curr._order > acc) {
          return curr._order;
        }
        return acc;
      }, 0);
      setMaxOrder(maxOrder);
    }
  }, [publicContent]);

  useEffect(() => {
    dispatch(fetchOrganisation());
  }, [dispatch]);

  useEffect(() => {
    if (organisation?.organisation_slug) {
      dispatch(getOrganisationPublicContent());
    }
  }, [dispatch, organisation]);

  const removeOrganisationContent = useCallback(() => {
    const contentToRemovePrimaryKeys = rowsToRemove.map(row => {
      const contentToRemove = publicContent.find(content => {
        if (content.product_id === row.product_id || content.product_id === row.id) {
          return true;
        }
        return false;
      });
      return contentToRemove.pk;
    });
    dispatch(removeOrganisationPublicContent(contentToRemovePrimaryKeys));
    setRowsToRemove([]);
  }, [dispatch, publicContent, rowsToRemove]);

  const showDeleteWarningModal = useCallback(() => {
    Modal.confirm({
      title: t('organisations:Content.delete.title'),
      content: t('organisations:Content.delete.content'),
      okText: t('organisations:Content.delete.ok'),
      okType: 'danger',
      cancelText: t('organisations:Content.delete.cancel'),
      onOk: removeOrganisationContent,
      onCancel() {
        return;
      },
    });
  }, [removeOrganisationContent, t]);

  const addOrganisationContent = useCallback(
    (_, rows) => {
      const contentToAdd = rows.map(row => {
        const contentType = row.metadata?.type?.toUpperCase() || row.type.toUpperCase();
        const formattedContentType = contentType.replace(/_/g, '');
        return {
          product_id: row.product_id || row.id,
          content_type: formattedContentType,
        };
      });
      dispatch(createOrganisationPublicContent(contentToAdd));
      setVisible(false);
    },
    [dispatch],
  );

  const currentContentIds = useMemo(() => {
    return (publicContent || []).map(content => content.product_id);
  }, [publicContent]);

  return (
    <>
      <ColumnGroup>
        <Column>
          <SearchableTable
            columns={columns}
            dataSource={content || []}
            extra={
              <div className="app-organisation-details-extra">
                <Popover
                  overlayClassName="app-organisation-details-extra-popover"
                  content={<p>{t('organisations:Content.popover.body')}</p>}
                  placement="bottomRight"
                  title={t('organisations:Content.popover.title')}
                  trigger="click"
                >
                  <IconButton
                    icon={faInfoCircle}
                    tooltip={t('common:buttons.info')}
                    onClick={() => {}}
                  />
                </Popover>
                <IconButton
                  disabled={rowsToRemove.length === 0 || editing}
                  icon={faMinusCircle}
                  tooltip={t('common:buttons.remove')}
                  onClick={showDeleteWarningModal}
                />
                <Button
                  className="add-btn"
                  disabled={editing || publicContent.length >= 30}
                  icon="plus"
                  onClick={() => setVisible(true)}
                >
                  {t('common:buttons.add')}
                </Button>
              </div>
            }
            filterFunc={filterFunc}
            loading={loading || editing || contentLoading}
            pagination={false}
            onRowClick={row => {
              switch (row.metadata?.type) {
                case DOCUMENT:
                  return history.push(`/content/documents/${row.id}`);
                case VIDEO:
                  return history.push(`/content/videos/${row.id}`);
                case FORM:
                  return history.push(`/content/forms/${row.id}`);
                case QUESTIONNAIRE:
                  return history.push(`/content/questionnaires/${row.id}`);
                case TASK:
                  return history.push(`/content/tasks/${row.id}`);
                case WEBLINK:
                case WEBSITE:
                case WEB_FORM:
                  return history.push(`/content/weblinks/${row.id}`);
                default:
                  return null;
              }
            }}
            onRowSelectionChange={(ids, rows) => setRowsToRemove(rows)}
          />
        </Column>
      </ColumnGroup>
      <SelectContentModal
        contentIdsToFilter={currentContentIds}
        contentTypes={contentTypes}
        multiSelect
        visible={visible}
        onCancel={() => setVisible(false)}
        onSubmit={addOrganisationContent}
        publishedOnly={true}
      />
    </>
  );
}

export default Content;
