import React from 'react';
import { number, func } from 'prop-types';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import './style.less';

function Reorderer({ order, maxOrder, pk, reorderer }) {
  const dispatch = useDispatch();
  const isMoveContentDownDisabled = order === maxOrder;
  const isMoveContentUpDisabled = order === 0;

  const moveContentUp = () => {
    if (order > 0) {
      const newOrderValue = order - 1;
      dispatch(reorderer({ primaryKey: pk, newOrder: newOrderValue }));
    }
  };

  const moveContentDown = () => {
    if (order < maxOrder) {
      const newOrderValue = order + 1;
      dispatch(reorderer({ primaryKey: pk, newOrder: newOrderValue }));
    }
  };

  return (
    <div className="reorderer-container">
      <Button onClick={moveContentUp} disabled={isMoveContentUpDisabled} icon="caret-up" />
      <Button onClick={moveContentDown} disabled={isMoveContentDownDisabled} icon="caret-down" />
    </div>
  );
}

Reorderer.propTypes = {
  order: number.isRequired,
  maxOrder: number.isRequired,
  pk: number.isRequired,
  reorderer: func.isRequired,
};

export default Reorderer;
