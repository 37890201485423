import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OPEN_CONTENT, OPEN_LINK } from '@constants';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { fetchContentMessages, updateContentMessage } from '@redux/contentMessages/actions';
import {
  selectContentMessage,
  selectContentMessagesUpdating,
} from '@redux/contentMessages/selectors';
import { DOCUMENT, VIDEO } from '@utils/contentTypes';
import { determineSourceTypeForNewContent } from '@utils';
import MessageWizard from './MessageWizard';

function getPayloadOptionsData(content) {
  switch (content.action) {
    case OPEN_CONTENT:
      return content.contentData;
    case OPEN_LINK:
      return content.websiteLink;
    default:
      return null;
  }
}

export default ({
  type,
  messageTranslationKey,
  match: {
    params: { id },
  },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissionsForUser);

  useEffect(() => {
    dispatch(fetchContentMessages());
  }, [dispatch]);

  const [loading, message] = useSelector(selectContentMessage(id));

  const updating = useSelector(selectContentMessagesUpdating);

  const onCompleted = useCallback(
    messageData => {
      const { details, content } = messageData;
      const updatedMessage = {
        name: details.name,
        published: details.status === 'publish' ? true : false,
        version: message ? message.version + 1 : 1,
        id,
        language: details.language,
        body: content.content,
        source: determineSourceTypeForNewContent(permissions),
        created: message.created,
        payload: {
          action: content.action,
          data: getPayloadOptionsData(content),
        },
        metadata: {
          type,
        },
      };

      if (
        updatedMessage.payload.data?.metadata &&
        [DOCUMENT, VIDEO].includes(updatedMessage.payload.data.metadata.type)
      ) {
        const {
          id,
          latest_version,
          metadata: { language, tags, type },
          name,
          owner_id,
          product_id,
          slug,
          token,
        } = updatedMessage.payload.data;

        updatedMessage.payload.data = {
          id,
          latest_version: { name: latest_version.name, description: latest_version.description },
          metadata: { language, tags, type },
          name,
          owner_id,
          product_id,
          slug,
          token,
        };
      }

      dispatch(updateContentMessage(updatedMessage, type, messageTranslationKey, id));
    },
    [dispatch, id, message, messageTranslationKey, type, permissions],
  );

  const wizardLoaading = loading || (!loading && !message);

  return (
    <MessageWizard
      exitMessage={t(`${messageTranslationKey}:EditContentMessageWizard.exitConfirmation`)}
      initialMessage={message}
      loading={wizardLoaading}
      messageTranslationKey={messageTranslationKey}
      submitting={updating}
      title={t(`${messageTranslationKey}:EditContentMessageWizard.title`)}
      type={type}
      onCompleted={onCompleted}
    />
  );
};
