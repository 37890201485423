import React from 'react';
import { Icon, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { contentTypes, contentTypesList } from '@utils/contentTypes';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { pathway } from '@assets/icons';
import { selectForm } from '@redux/forms/reducers';
import {
  DOCUMENT,
  VIDEO,
  FORM,
  QUESTIONNAIRE,
  TASK,
  WEBLINK,
  MESSAGE,
  ALERT,
  REMINDER,
} from '@utils/contentTypes';
import { mapPathwaysWhatToDashboardWhat } from '../../../redux/rules/utils';
import {
  getEventForRuleExecution,
  filterEntriesByEvent,
  FORM_SUBMISSION,
  RULE_EXECUTION,
  STAGE_TRANSITION,
  UNSUPPORTED_ACTION,
  ADHOC_MESSAGE,
  OTHER,
} from './utils';
import { TRANSITION_NEXT } from '@constants';
import DateFilter from '@components/DateFilter';

const contentTypeToDetailsBasePath = {
  FEATURE_DOCUMENT: '/content/documents/',
  FEATURE_VIDEO: '/content/videos/',
  FEATURE_FORM: '/content/forms/',
  FEATURE_QUESTIONNAIRE: '/content/questionnaires/',
  FEATURE_TASK: '/content/tasks/',
  FEATURE_WEBLINK: '/content/weblinks/',
  MESSAGE: '/content/messages/',
  MESSAGE_ALERT: '/content/alerts/',
  MESSAGE_REMINDER: '/content/reminders/',
};

function extractFormId(formDataId) {
  const extractedFormId = /[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/.exec(
    formDataId,
  );

  if (extractedFormId) {
    return extractedFormId[0];
  }

  return false;
}

function FormSubmissionEvent({ formId }) {
  const { t } = useTranslation();
  const [loading, form] = useSelector(selectForm(formId));

  if (loading) return null;
  if (!form) return t('cards:JourneyTimeline.events.submittedForm');

  switch (form.metadata.type) {
    case TASK:
      return t('cards:JourneyTimeline.events.submittedTask');
    case QUESTIONNAIRE:
      return t('cards:JourneyTimeline.events.submittedQuestionnaire');
    case FORM:
    default:
      return t('cards:JourneyTimeline.events.submittedForm');
  }
}

function FormSubmissionActionedItem({ formId }) {
  const [loading, form] = useSelector(selectForm(formId));

  if (loading || !form) return null;

  return form.name;
}

function FormSubmissionTrigger({ formId }) {
  const { t } = useTranslation();
  const [loading, form] = useSelector(selectForm(formId));
  if (loading) return null;

  // TODO: link these to the form submission results
  switch (form?.metadata?.type) {
    case QUESTIONNAIRE:
      return t('cards:JourneyTimeline.triggers.questionnaire');
    case TASK:
      return t('cards:JourneyTimeline.triggers.task');
    default:
      if (form?.name === 'questionnaire 2') console.log('default:', form?.metadata?.type);
      return t('cards:JourneyTimeline.triggers.form');
  }
}

function createWhatTypeFromType(type) {
  switch (type) {
    case DOCUMENT:
    case VIDEO:
    case FORM:
    case QUESTIONNAIRE:
    case TASK:
    case WEBLINK:
      return `FEATURE_${type}`;
    case MESSAGE:
      return type;
    case ALERT:
    case REMINDER:
      return `${MESSAGE}_${type}`;
    default:
      return '';
  }
}

export default (t, appUserId, pathwayDataById = {}, contentItemsByRef = {}) => {
  return [
    {
      dataIndex: 'event_datetime',
      key: 'date',
      title: t('cards:JourneyTimeline.columns.date'),
      render: datetime => moment(datetime).format('LLL'),
      sorter: (a, b) => a.event_datetime.localeCompare(b.event_datetime),
      filterDropdown: DateFilter.filterDropdown,
      onFilter: (value, record) => DateFilter.onFilter(value, record.event_datetime),
    },
    {
      filters: [
        {
          text: t('cards:JourneyTimeline.filters.ruleExecutions'),
          value: t('cards:JourneyTimeline.filters.ruleExecutions'),
          children: contentTypesList.map(contentType => ({
            text: t(contentTypes[contentType].label),
            value: contentType,
          })),
        },
        {
          text: t('cards:JourneyTimeline.filters.stageTransitions'),
          value: t('cards:JourneyTimeline.filters.stageTransitions'),
          children: [
            {
              text: t('cards:JourneyTimeline.events.stageTransition'),
              value: STAGE_TRANSITION,
            },
          ],
        },
        {
          text: t('cards:JourneyTimeline.filters.formSubmissions'),
          value: t('cards:JourneyTimeline.filters.formSubmissions'),
          children: [
            {
              text: t('cards:JourneyTimeline.events.submittedForm'),
              value: 'form_submitted',
            },
            {
              text: t('cards:JourneyTimeline.events.submittedQuestionnaire'),
              value: 'questionnaire_submitted',
            },
            {
              text: t('cards:JourneyTimeline.events.submittedTask'),
              value: 'task_submitted',
            },
          ],
        },
      ],
      key: 'action',
      title: t('cards:JourneyTimeline.columns.event'),
      onFilter: filterEntriesByEvent,
      render: record => {
        switch (record.type) {
          case STAGE_TRANSITION:
            return (
              <>
                <Icon component={pathway} /> {t('cards:JourneyTimeline.events.stageTransition')}
              </>
            );
          case RULE_EXECUTION:
            return getEventForRuleExecution(record, t);
          case FORM_SUBMISSION:
            const extractedFormId = extractFormId(record.data.form_id);

            if (extractedFormId) {
              return <FormSubmissionEvent formId={extractedFormId} />;
            }

            return t('cards:JourneyTimeline.events.submittedForm');
          case ADHOC_MESSAGE:
            switch (record.data.metadata?.type) {
              case ALERT:
                return t('cards:JourneyTimeline.events.adhocAlert');
              case REMINDER:
                return t('cards:JourneyTimeline.events.adhocReminder');
              default:
                return t('cards:JourneyTimeline.events.adhocMessage');
            }
          case OTHER:
            if (record.data.event_type === 'connect_to_organisation') {
              return t('cards:JourneyTimeline.events.connect');
            } else if (record.data.engagement_check) {
              return t('cards:JourneyTimeline.events.engagementCheck');
            } else {
              return t('cards:JourneyTimeline.events.disconnect');
            }
          default:
            return UNSUPPORTED_ACTION;
        }
      },
    },
    {
      title: t('cards:JourneyTimeline.columns.audience.title'),
      key: 'id',
      render: record => {
        const audience =
          record?.data?.rule_what_details?.metadata?.tags?.find(tag => tag.label === 'AUDIENCE')
            ?.term ||
          record?.data?.rule_what_details?.latest_version?.metadata?.tags?.find(
            tag => tag.label === 'AUDIENCE',
          )?.term ||
          record?.data?.rule_what_details?.metadata?.audience ||
          record?.data?.rule_what_details?.latestVersion?.metadata?.audience;
        if (audience) return t(`cards:JourneyTimeline.columns.audience.${audience}`);
        return '';
      },
    },
    {
      dataIndex: ['data', 'pathway_id'],
      filters: Object.values(pathwayDataById).map(({ name: pathwayName }) => ({
        text: pathwayName,
        value: pathwayName,
      })),
      key: 'pathway',
      title: t('cards:JourneyTimeline.columns.pathway'),
      render: pathwayId => pathwayDataById[pathwayId]?.name,
      // render: record => {
      //   debugger;
      //   return pathwayDataById[record.data.pathway_id]?.name
      // },
      onFilter: (value, record) => value === pathwayDataById[record.data.pathway_id]?.name,
    },
    {
      filters: [
        { text: t('cards:JourneyTimeline.triggers.stage'), value: STAGE_TRANSITION },
        { text: t('cards:JourneyTimeline.triggers.rule'), value: RULE_EXECUTION },
        { text: t('cards:JourneyTimeline.triggers.form'), value: FORM_SUBMISSION },
      ],
      key: 'trigger',
      title: t('cards:JourneyTimeline.columns.trigger'),
      onFilter: (value, record) => value === record.type,
      render: record => {
        switch (record.type) {
          case STAGE_TRANSITION:
            return t('cards:JourneyTimeline.triggers.stage');
          case RULE_EXECUTION:
            return (
              <Tooltip title={record.data.rule_name}>
                {record.data.entry_type === 'check'
                  ? t('cards:JourneyTimeline.triggers.engagementCheck')
                  : t('cards:JourneyTimeline.triggers.rule')}
              </Tooltip>
            );
          case FORM_SUBMISSION:
            const extractedFormId = extractFormId(record.data.form_id);

            if (extractedFormId) {
              return <FormSubmissionTrigger formId={extractedFormId} />;
            }

            return t('cards:JourneyTimeline.triggers.form');
          case ADHOC_MESSAGE:
            switch (record.data.metadata?.type) {
              case ALERT:
                return t('cards:JourneyTimeline.triggers.adhocAlert');
              case REMINDER:
                return t('cards:JourneyTimeline.triggers.adhocReminder');
              default:
                return t('cards:JourneyTimeline.triggers.adhocMessage');
            }
          case OTHER:
            if (record.data.engagement_check) {
              if (record.data.event) {
                return `${record.data.debug ? '[DEBUG] ' : ''} -> Check ID ${
                  record.data.engagement_check.id
                } ; Content ID: ${record.data.event.content_id}`;
              } else {
                return `Check ID ${record.data.engagement_check.id}`;
              }
              // return `${record.data.event.content_type}/${record.data.event.content_id}`;
            }
            return t('cards:JourneyTimeline.triggers.userAction');
          default:
            return UNSUPPORTED_ACTION;
        }
      },
    },
    {
      key: 'actionedItem',
      title: t('cards:JourneyTimeline.columns.actionedItem'),
      render: record => {
        switch (record.type) {
          case STAGE_TRANSITION:
            return (
              <Link
                to={`/procedure/pathways/${pathwayDataById[record.data.pathway_id]?.originalId}`}
              >
                {record.data.previous_stage_name || t('cards:JourneyTimeline.pathwayAdded')}{' '}
                <FontAwesomeIcon icon={faArrowRight} /> {record.data.new_stage_name}
              </Link>
            );
          case RULE_EXECUTION:
            if (record.data.rule_what_type === TRANSITION_NEXT) {
              return (
                <Link
                  to={`${
                    contentTypeToDetailsBasePath[
                      mapPathwaysWhatToDashboardWhat(
                        createWhatTypeFromType(record.data.rule_when_details.metadata?.type),
                        { content_type: record.data.rule_when_details.metadata?.type },
                      )
                    ]
                  }${record.data.rule_when_details.id || record.data.rule_when_details.uuid}`}
                >
                  {record.data.rule_when_details.name}
                </Link>
              );
            }

            if (record.data.rule_what_type === MESSAGE) {
              if (record.data.entry_type === 'check') {
                return (
                  <Link to={`/content/messages/${record.data.rule_what_details.id}`}>
                    {record.data.rule_what_details.title || record.data.rule_what_details.name}
                  </Link>
                );
              }
              return (
                <Link to={`/procedure/rules/${record.data.original_rule_id}`}>
                  {record.data.rule_what_details.title || record.data.rule_what_details.name}
                </Link>
              );
            }

            return (
              <Link
                to={`${
                  contentTypeToDetailsBasePath[
                    mapPathwaysWhatToDashboardWhat(
                      record.data.rule_what_type,
                      record.data.rule_what_details,
                    )
                  ]
                }${record.data.rule_what_details.id || record.data.rule_what_details.uuid}`}
              >
                {record.data.rule_what_details.name || record.data.rule_what_details.title}
              </Link>
            );
          case FORM_SUBMISSION:
            if (record.data.content) {
              const contentDetails = contentItemsByRef[record.data.content];
              if (contentDetails && appUserId) {
                return (
                  <Link
                    to={`/patients/individuals/${appUserId}/${contentDetails.content_type.toLowerCase()}s/${
                      contentDetails.id
                    }/${record.data.submission_uuid}/`}
                  >
                    {contentDetails.content_name}
                  </Link>
                );
              }
              return contentItemsByRef[record.data.content]?.content_name || record.data.content;
            }

            const extractedFormId = extractFormId(record.data.form_id);

            if (extractedFormId) {
              return <FormSubmissionActionedItem formId={extractedFormId} />;
            }

            return null;
          case ADHOC_MESSAGE:
            switch (record.data.metadata?.type) {
              case ALERT:
                return t('cards:JourneyTimeline.triggers.adhocAlert');
              case REMINDER:
                return t('cards:JourneyTimeline.triggers.adhocReminder');
              default:
                return t('cards:JourneyTimeline.triggers.adhocMessage');
            }
          case OTHER:
            if (record.data.engagement_check) {
              return record.data.result;
            }
            return t('cards:JourneyTimeline.notApplicable');
          default:
            return UNSUPPORTED_ACTION;
        }
      },
    },
  ];
};
