import { call, select } from 'redux-saga/effects';
import { selectAppDetails } from '@organisation/redux/selectors';
import { IFormComputation, IRawFormComputation } from '@redux/appUsers/types';
import { PIPObject } from '@api/pipClient';
import { IForm, IRawForm } from './types';
import { FORM } from '@utils/contentTypes';
import ContentRef from '@utils/contentRef';

export function* getBaseFormsObjectType() {
  const { appToken } = yield select(selectAppDetails);
  return `${appToken}-forms`;
}

export function* getGlobalFormsObjectType(): any {
  const BASE_FORMS_OBJECT_TYPE = yield call(getBaseFormsObjectType);

  return `${BASE_FORMS_OBJECT_TYPE}-global`;
}

export function* getBaseObjectTypeForForm(formId: string) {
  const { appToken } = yield select(selectAppDetails);
  const BASE_FORM_OBJECT_TYPE = `${appToken}-form`;
  return `${BASE_FORM_OBJECT_TYPE}-${formId}`;
}

export const parseFormComputations = (
  rawComputations: PIPObject<IRawFormComputation>[],
): IFormComputation[] =>
  rawComputations.map(rawComputation => ({
    dataObjectUUID: rawComputation.json.data_object_uuid,
    dataObjectVersion: rawComputation.json.data_object_version,
    latest: rawComputation.json.latest,
    previous: rawComputation.json.previous,
  }));

export function parseRawForm(rawForm: IRawForm, organisationId: string): IForm {
  const {
    created,
    description,
    is_available,
    latest_version,
    // modified,
    name,
    // publishing_records,
    // slug,
    url,
    uuid,
    // versions,
    audience_type,
  } = rawForm;

  const { ui_schema, ...latestVersionWithoutUISchema } = latest_version;
  const contentRef = new ContentRef(
    organisationId,
    'form',
    rawForm.uuid,
    rawForm.latest_version?.number.toString(),
  );

  return {
    created,
    description,
    language: latest_version?.metadata.language ?? 'en',
    latestVersion: {
      ...latestVersionWithoutUISchema,
      uiSchema: ui_schema,
    },
    metadata: {
      fileName: '',
      type: latest_version?.metadata.type ?? FORM,
    },
    name,
    published: is_available,
    type: FORM,
    url,
    uuid,
    version: latest_version?.name ?? 1,
    audienceType: audience_type,
    content: contentRef?.toString(),
  };
}
