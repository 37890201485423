import React from 'react';
import { bool, shape } from 'prop-types';
import DocumentDetails from '@cards/Documents/Details';
import FormDetails from '@cards/Forms/FormDetails';
import { documentTypes, formTypes, WEBLINK, WEBSITE, WEB_FORM } from '@utils/contentTypes';
import WeblinkDetails from '@cards/Weblinks/Details';
import { getBasicContentDetails } from '@utils/contentDetails';

function AttachedContent({ content }) {
  const contentDetails = content ? getBasicContentDetails(content) : {};

  if (documentTypes.includes(contentDetails.content_type)) {
    const documentTranslationKey = contentDetails.content_type === 'VIDEO' ? 'videos' : 'documents';
    return (
      <DocumentDetails
        documentId={content.id}
        documentTranslationKey={documentTranslationKey}
        title="cards:SentMessageAttachedContent.title"
      />
    );
  }

  if (formTypes.includes(contentDetails.content_type)) {
    return <FormDetails formId={content.id} title="cards:SentMessageAttachedContent.title" />;
  }

  if ([WEBLINK, WEB_FORM, WEBSITE].includes(contentDetails?.content_type)) {
    return (
      <WeblinkDetails
        showWarning
        title="cards:SentMessageAttachedContent.title"
        weblinkId={content.id}
      />
    );
  }

  return null;
}

AttachedContent.propTypes = {
  content: shape({}),
  loading: bool,
};

AttachedContent.defaultProps = {
  content: null,
  loading: false,
};

export default AttachedContent;
