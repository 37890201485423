import React from 'react';
import { createLanguageFilters } from '@utils/supportedLanguages';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { trimTextToApproximateLength } from '@utils';

export default (t, messageTranslationKey) => [
  {
    className: 'language-column',
    title: t('cards:MessagesList.columns.language.title'),
    dataIndex: 'latestVersion',
    key: 'id',
    render: latestVersion => <LanguageIcon language={latestVersion?.metadata?.language || 'en'} />,
    filters: createLanguageFilters(t),
    onFilter: (value, record) => record.language === value,
    width: 125,
  },
  {
    title: t(`cards:MessagesList.columns.${messageTranslationKey}.title`),
    dataIndex: 'name',
    key: 'name',
    render: name => trimTextToApproximateLength(name, 20),
    sorter: (a, b) => (a.name || '').localeCompare(b.title || ''),
  },
  {
    title: t('cards:MessagesList.columns.description'),
    dataIndex: ['latestVersion', 'body'],
    key: 'body',
    render: description => trimTextToApproximateLength(description),
    sorter: (a, b) => (a.latestVersion.body || '').localeCompare(b.latestVersion.body || ''),
  },
  {
    className: 'published-column',
    title: t('cards:MessagesList.columns.published.title'),
    dataIndex: 'published',
    key: 'published',
    render: published => <PublishedStatusIcon published={published} />,
    filters: [
      {
        text: t('cards:MessagesList.columns.published.published'),
        value: true,
      },
      {
        text: t('cards:MessagesList.columns.published.unpublished'),
        value: false,
      },
    ],
    onFilter: (value, record) => record.published === value,
  },
];
