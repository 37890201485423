import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Input, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import {
  faBell,
  faCalendarStar,
  faEdit,
  faEnvelope,
  faExclamationTriangle,
  faFileAlt,
  faFileEdit,
  faLink,
  faQuestionCircle,
  faTasks,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DELAY,
  INDEX_EVENT,
  INDEX_EVENT_DELAY,
  STAGE_TRANSITION,
  FORM_SUBMITTED,
  QUESTIONNAIRE_SUBMITTED,
  TASK_SUBMITTED,
  DOCUMENT_VIEWED,
  VIDEO_VIEWED,
  WEBLINK_VISITED,
  MESSAGE_VIEWED,
  ALERT_VIEWED,
  REMINDER_VIEWED,
  APP_LAUNCHED,
  FILE_SHARED,
} from '@constants';
import { FORM, QUESTIONNAIRE, TASK, WEBSITE, WEB_FORM, DOCUMENT, VIDEO } from '@utils/contentTypes';
import SelectContentModal from '@components/SelectContentModal';
import SelectIndexEventModal from './SelectIndexEventModal';
import './style.less';
import SelectMessageModal from '../Content/SelectModals/SelectMessage';
import SelectAlertModal from '../Content/SelectModals/SelectAlert';
import SelectReminderModal from '../Content/SelectModals/SelectReminder';

const formSubmittedWhenTypes = [FORM_SUBMITTED, QUESTIONNAIRE_SUBMITTED, TASK_SUBMITTED];

function Timing() {
  const [whenModalType, setWhenModalType] = useState(null);
  const [whenDetail, setWhenDetail] = useState(null);
  const { i18n, t } = useTranslation();
  const { currentStepData, form, formData, setCurrentStepData } = useWizard();
  const formSubmittedContentTypes = useMemo(() => {
    switch (whenModalType) {
      case FORM_SUBMITTED:
        return [FORM];
      case QUESTIONNAIRE_SUBMITTED:
        return [QUESTIONNAIRE];
      case TASK_SUBMITTED:
        return [TASK];
      default:
        return [];
    }
  }, [whenModalType]);

  useEffect(() => {
    if (currentStepData.whenDetail) {
      setWhenDetail(currentStepData.whenDetail);
      form.setFieldsValue({ whenDetail: currentStepData.whenDetail });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelection = useCallback(
    e => {
      setWhenModalType(e.target.value);
      if (whenDetail) {
        setWhenDetail(null);
        form.setFieldsValue({ whenDetail: null });
      }
    },
    [form, whenDetail],
  );

  const onCancel = useCallback(
    data => {
      setWhenModalType(null);
      if (!whenDetail || !(form.getFieldValue('when') || '').includes(whenDetail?.metadata?.type)) {
        form.setFieldsValue({ when: null, whenDetail: null });
      }
    },
    [form, whenDetail],
  );
  const onOk = useCallback(
    (id, [data]) => {
      setWhenModalType(null);
      setWhenDetail(data);
      form.setFieldsValue({ days: null, whenDetail: data });
      setCurrentStepData({ ...currentStepData, days: null, whenDetail: data });
    },
    [currentStepData, form, setCurrentStepData],
  );

  const onOkMessage = useCallback(
    message => {
      onOk(null, [message]);
    },
    [onOk],
  );

  return (
    <>
      <Card.Half className="procedure-rule-card select-timing">
        <Form>
          <fieldset>
            <legend>{t('pathways:ProcedureRules.wizard.timing.header')}</legend>

            <p className="info-text">{t('pathways:ProcedureRules.wizard.timing.info')}</p>

            <Form.Item>
              {form.getFieldDecorator('when', {
                initialValue: currentStepData.when,
                rules: [
                  {
                    required: true,
                    message: t('pathways:ProcedureRules.wizard.timing.validationError'),
                  },
                ],
              })(
                <Radio.Group onChange={handleSelection}>
                  <Radio value={STAGE_TRANSITION}>
                    {t('pathways:ProcedureRules.wizard.timing.enterStage')}
                  </Radio>
                  <Radio value="DELAY">
                    {t('pathways:ProcedureRules.wizard.timing.afterStage')}
                  </Radio>
                  {form.getFieldValue('when') === DELAY ? (
                    <div className="option-inputs">
                      <span>{t('pathways:ProcedureRules.wizard.timing.timeInDays')}</span>
                      <Form.Item hasFeedback>
                        {form.getFieldDecorator('days', {
                          initialValue: currentStepData.days,
                          rules: [
                            {
                              required: true,
                              message: t(
                                'pathways:ProcedureRules.wizard.timing.daysRequiredValidationError',
                              ),
                            },
                            {
                              pattern: /^\d{0,3}$/,
                              message: t(
                                'pathways:ProcedureRules.wizard.timing.daysDigitsValidationError',
                              ),
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  ) : null}

                  <br />
                  <Radio value={INDEX_EVENT}>
                    {t('pathways:ProcedureRules.wizard.timing.indexEvent')}
                  </Radio>
                  {form.getFieldValue('when') === INDEX_EVENT ? (
                    <div className="option-inputs">
                      {/* We have no real way to tell if whenDetail is an indexEvent, so we use the existence of translated names */}
                      {whenDetail && whenDetail.translatedNames ? (
                        <div className="index-event-display">
                          <span>
                            <FontAwesomeIcon icon={faCalendarStar} />
                            {whenDetail?.translatedNames[i18n.language.slice(0, 2)] ||
                              whenDetail.name}
                          </span>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => setWhenModalType(INDEX_EVENT)}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}
                  <Radio value={INDEX_EVENT_DELAY}>
                    {t('pathways:ProcedureRules.wizard.timing.afterIndexEvent')}
                  </Radio>
                  {form.getFieldValue('when') === INDEX_EVENT_DELAY &&
                  whenDetail &&
                  whenDetail.translatedNames ? (
                    <div className="option-inputs">
                      <div className="index-event-display">
                        <span>
                          <FontAwesomeIcon icon={faCalendarStar} />
                          {(whenDetail.translatedNames &&
                            whenDetail.translatedNames[i18n.language.slice(0, 2)]) ||
                            whenDetail.name}
                        </span>
                        <FontAwesomeIcon
                          icon={faEdit}
                          onClick={() => setWhenModalType(INDEX_EVENT_DELAY)}
                        />
                      </div>
                      <span>
                        {t('pathways:ProcedureRules.wizard.timing.timeInDaysNegativeSupported')}
                      </span>
                      <Form.Item hasFeedback>
                        {form.getFieldDecorator('days', {
                          initialValue: currentStepData.days,
                          rules: [
                            {
                              required: true,
                              message: t(
                                'pathways:ProcedureRules.wizard.timing.daysRequiredValidationError',
                              ),
                            },
                            {
                              pattern: /^-?\d{0,3}$/,
                              message: t(
                                'pathways:ProcedureRules.wizard.timing.daysDigitsWithNegativeValidationError',
                              ),
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  ) : null}

                  <br />
                  <Radio value={FORM_SUBMITTED}>
                    {t('pathways:ProcedureRules.wizard.timing.formSubmitted')}
                  </Radio>
                  {FORM_SUBMITTED === form.getFieldValue('when') ? (
                    <div className="option-inputs">
                      {whenDetail ? (
                        <div className="index-event-display">
                          <span>
                            <FontAwesomeIcon icon={faFileEdit} />
                            {whenDetail.name}
                          </span>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => setWhenModalType(form.getFieldValue('when'))}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}
                  <Radio value={QUESTIONNAIRE_SUBMITTED}>
                    {t('pathways:ProcedureRules.wizard.timing.questionnaireSubmitted')}
                  </Radio>
                  {QUESTIONNAIRE_SUBMITTED === form.getFieldValue('when') ? (
                    <div className="option-inputs">
                      {whenDetail ? (
                        <div className="index-event-display">
                          <span>
                            <FontAwesomeIcon icon={faQuestionCircle} />
                            {whenDetail.name}
                          </span>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => setWhenModalType(form.getFieldValue('when'))}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}
                  <Radio value={TASK_SUBMITTED}>
                    {t('pathways:ProcedureRules.wizard.timing.taskSubmitted')}
                  </Radio>
                  {TASK_SUBMITTED === form.getFieldValue('when') ? (
                    <div className="option-inputs">
                      {whenDetail ? (
                        <div className="index-event-display">
                          <span>
                            <FontAwesomeIcon icon={faTasks} />
                            {whenDetail.name}
                          </span>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => setWhenModalType(form.getFieldValue('when'))}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}

                  <br />
                  <Radio value={DOCUMENT_VIEWED}>
                    {t('pathways:ProcedureRules.wizard.timing.documentViewed')}
                  </Radio>
                  {DOCUMENT_VIEWED === form.getFieldValue('when') ? (
                    <div className="option-inputs">
                      {whenDetail ? (
                        <div className="index-event-display">
                          <span>
                            <FontAwesomeIcon icon={faFileAlt} />
                            {whenDetail.name}
                          </span>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => setWhenModalType(form.getFieldValue('when'))}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}
                  <Radio value={VIDEO_VIEWED}>
                    {t('pathways:ProcedureRules.wizard.timing.videoViewed')}
                  </Radio>
                  {VIDEO_VIEWED === form.getFieldValue('when') ? (
                    <div className="option-inputs">
                      {whenDetail ? (
                        <div className="index-event-display">
                          <span>
                            <FontAwesomeIcon icon={faFileAlt} />
                            {whenDetail.name}
                          </span>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => setWhenModalType(form.getFieldValue('when'))}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}

                  <br />
                  <Radio value={WEBLINK_VISITED}>
                    {t('pathways:ProcedureRules.wizard.timing.weblinkVisited')}
                  </Radio>
                  {WEBLINK_VISITED === form.getFieldValue('when') ? (
                    <div className="option-inputs">
                      {whenDetail ? (
                        <div className="index-event-display">
                          <span>
                            <FontAwesomeIcon icon={faLink} />
                            {whenDetail.name}
                          </span>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => setWhenModalType(form.getFieldValue('when'))}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}

                  <br />
                  <Radio value={MESSAGE_VIEWED}>
                    {t('pathways:ProcedureRules.wizard.timing.messageViewed')}
                  </Radio>
                  {MESSAGE_VIEWED === form.getFieldValue('when') ? (
                    <div className="option-inputs">
                      {whenDetail ? (
                        <div className="index-event-display">
                          <span>
                            <FontAwesomeIcon icon={faEnvelope} />
                            {whenDetail.name}
                          </span>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => setWhenModalType(form.getFieldValue('when'))}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}

                  <Radio value={ALERT_VIEWED}>
                    {t('pathways:ProcedureRules.wizard.timing.alertViewed')}
                  </Radio>
                  {ALERT_VIEWED === form.getFieldValue('when') ? (
                    <div className="option-inputs">
                      {whenDetail ? (
                        <div className="index-event-display">
                          <span>
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                            {whenDetail.name}
                          </span>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => setWhenModalType(form.getFieldValue('when'))}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}

                  <Radio value={REMINDER_VIEWED}>
                    {t('pathways:ProcedureRules.wizard.timing.reminderViewed')}
                  </Radio>
                  {REMINDER_VIEWED === form.getFieldValue('when') ? (
                    <div className="option-inputs">
                      {whenDetail ? (
                        <div className="index-event-display">
                          <span>
                            <FontAwesomeIcon icon={faBell} />
                            {whenDetail.name}
                          </span>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => setWhenModalType(form.getFieldValue('when'))}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}

                  <br />
                  <Radio value={APP_LAUNCHED}>
                    {t('pathways:ProcedureRules.wizard.timing.appLaunched')}
                  </Radio>
                  <Radio value={FILE_SHARED}>
                    {t('pathways:ProcedureRules.wizard.timing.fileShared')}
                  </Radio>

                  {/* <Radio value={MANUAL}>
                    {t('pathways:ProcedureRules.wizard.timing.manually')}
                  </Radio> */}
                </Radio.Group>,
              )}
            </Form.Item>
            {/* sneaky form field to store timing data */}
            {form.getFieldDecorator('whenDetail')(<span />)}
          </fieldset>
        </Form>
        {whenModalType === INDEX_EVENT || whenModalType === INDEX_EVENT_DELAY ? (
          <SelectIndexEventModal selectedIndexEvent={whenDetail} onCancel={onCancel} onOk={onOk} />
        ) : null}
        {formSubmittedWhenTypes.includes(whenModalType) ? (
          <SelectContentModal
            contentTypes={formSubmittedContentTypes}
            defaultFilteredValue={{ language: [formData.details.language] }}
            visible={formSubmittedWhenTypes.includes(whenModalType)}
            onCancel={onCancel}
            onSubmit={onOk}
          />
        ) : null}
        {whenModalType === DOCUMENT_VIEWED && (
          <SelectContentModal
            contentTypes={[DOCUMENT]}
            defaultFilteredValue={{ language: [formData.details.language] }}
            visible={whenModalType === DOCUMENT_VIEWED}
            onCancel={onCancel}
            onSubmit={onOk}
          />
        )}
        {whenModalType === VIDEO_VIEWED && (
          <SelectContentModal
            contentTypes={[VIDEO]}
            defaultFilteredValue={{ language: [formData.details.language] }}
            visible={whenModalType === VIDEO_VIEWED}
            onCancel={onCancel}
            onSubmit={onOk}
          />
        )}
        {whenModalType === WEBLINK_VISITED && (
          <SelectContentModal
            contentTypes={[WEBSITE, WEB_FORM]}
            defaultFilteredValue={{ language: [formData.details.language] }}
            visible={whenModalType === WEBLINK_VISITED}
            onCancel={onCancel}
            onSubmit={onOk}
          />
        )}
        {whenModalType === MESSAGE_VIEWED && (
          <SelectMessageModal
            defaultFilteredValue={{ language: [formData.details.language] }}
            onCancel={onCancel}
            onOk={onOkMessage}
          />
        )}
        {whenModalType === ALERT_VIEWED && (
          <SelectAlertModal
            defaultFilteredValue={{ language: [formData.details.language] }}
            onCancel={onCancel}
            onOk={onOkMessage}
          />
        )}
        {whenModalType === REMINDER_VIEWED && (
          <SelectReminderModal
            defaultFilteredValue={{ language: [formData.details.language] }}
            onCancel={onCancel}
            onOk={onOkMessage}
          />
        )}
      </Card.Half>
    </>
  );
}

export default Timing;
