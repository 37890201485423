import { Language, ISetLanguages, ISetOwners, Owner } from './actions';
import { combineReducers } from 'redux';
import { IState } from '@redux/reducer';
import ContentRef from '@utils/contentRef';
import { IDocument } from '@redux/documents/types';
import { IContentMessage } from '@redux/contentMessages/types';
import { IForm } from '@redux/forms/types';
import { Weblink } from '@liquid-state/ubiquity-client/dist/v2/types';

const languagesInitial: { list: string[]; byId: { [key: string]: Language } } = {
  list: [],
  byId: {},
};

const languages = (state = languagesInitial, action: ISetLanguages) => {
  switch (action.type) {
    case 'core/setLanguages':
      return {
        list: action.payload.languages.map(l => l.key),
        byId: action.payload.languages.reduce(
          (acc, l) => ({
            ...acc,
            [l.key]: l,
          }),
          {} as { [key: string]: any },
        ),
      };
    default:
      return state;
  }
};

const ownersInitial: { list: string[]; byId: { [key: string]: Owner } } = {
  list: [],
  byId: {},
};

const owners = (state = ownersInitial, action: ISetOwners) => {
  switch (action.type) {
    case 'core/setOwners':
      return {
        list: action.payload.owners.map(o => o.key),
        byId: action.payload.owners.reduce(
          (acc, o) => ({
            ...acc,
            [o.key]: o,
          }),
          {} as { [key: string]: any },
        ),
      };
    default:
      return state;
  }
};

export default combineReducers({
  languages,
  owners,
});

export const selectLanguages = (state: IState) =>
  state.core.languages.list.map(l => state.core.languages.byId[l]);
export const selectLanguage = (key: string) => (state: IState): Language | undefined =>
  state.core.languages.byId[key?.toLowerCase()];
export const selectOwners = (state: IState) =>
  state.core.owners.list.map(o => state.core.owners.byId[o]);
export const selectOwner = (key: string) => (state: IState): Owner | undefined => {
  return state.core.owners.byId[key?.toLowerCase()];
};

export type AnyContent = IDocument | IContentMessage | IForm | Weblink;
export type AnyContentMap = { [key: string]: AnyContent };

// Selects content of any type based on content references, which as a core part of our pep application going forward,
// is defined here in core, even though the actual state for each of these content types has their own reducer.
export const selectContent = (refs: string[]) => (state: IState): AnyContentMap => {
  const result: AnyContentMap = {};
  for (const r of refs) {
    const ref = ContentRef.fromString(r);
    let content: AnyContent | undefined = undefined;
    switch (ref.contentType) {
      case 'document':
        const docId = state.documents.contentIdToId[ref.contentId];
        content = state.documents.byId[docId];
        break;
      case 'message':
        content = state.contentMessages.byId[ref.contentId];
        break;
      case 'form':
        content = state.forms.byId[ref.contentId];
        break;
      case 'weblink':
        content = state.weblinks.byId[ref.contentId];
        break;
    }

    if (content) {
      result[r] = content;
    }
  }

  return result;
};
