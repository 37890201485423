import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func, string, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Modal, Table, Button } from 'antd';
import { selectRulesFilteredByWhen } from '@pathways/redux/rules/reducers';
import { fetchRules } from '@pathways/redux/rules/actions';
import { useSearch } from '@hooks';
import createColumns from './createColumns';

const filterFunc = (value, comparator) => comparator(value.name);

function SelectRulesModal({
  defaultFilteredValue,
  filters,
  selectedRules,
  onCancel,
  onOk,
  customRules,
  singleSelect,
  audienceTypesToFilter,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [previouslySelectedIds, setPreviouslySelectedIds] = useState(new Set());
  const selector = useCallback(selectRulesFilteredByWhen(filters), [filters]);

  const [loading, rulesFromSelector] = useSelector(selector);
  const rules = customRules || rulesFromSelector;
  const columns = useMemo(() => createColumns(t, defaultFilteredValue), [defaultFilteredValue, t]);

  const onOkClick = useCallback(() => {
    const newlySelectedRules = rules.filter(rule => selectedRowKeys.includes(rule.id));
    onOk(newlySelectedRules);
    setPreviouslySelectedIds(new Set([...previouslySelectedIds, ...selectedRowKeys]));
    setSelectedRowKeys([]);
    onCancel();
  }, [rules, selectedRowKeys, previouslySelectedIds, onCancel, onOk]);

  useEffect(() => {
    if (!customRules) {
      dispatch(fetchRules());
    }
  }, [dispatch, customRules]);

  useEffect(() => {
    if (selectedRules) {
      const selectedIds = selectedRules.map(sr => sr.id);
      setPreviouslySelectedIds(new Set(selectedIds));
    }
  }, [selectedRules]);

  const [searchResults, searchProps] = useSearch(rules, filterFunc);

  const dataSource = useMemo(() => {
    return searchResults.filter(
      result =>
        !previouslySelectedIds.has(result.id) &&
        !audienceTypesToFilter.includes(result.audience_type),
    );
  }, [searchResults, previouslySelectedIds, audienceTypesToFilter]);

  return (
    <Modal
      className="choose-content-modal"
      destroyOnClose
      title={t('pathways:ProcedurePathways.wizard.stagedRules.modalTitle')}
      visible
      onCancel={onCancel}
      onOk={!singleSelect ? onOkClick : undefined}
      footer={
        singleSelect
          ? [
              <div className="footer-left" key="footer-button-select-all" />,
              <div className="footer-right" key="footer-button-add">
                <Button disabled={!selectedRowKeys.length} type="primary" onClick={onOkClick}>
                  {t('common:selectionModal.add')}
                </Button>
              </div>,
            ]
          : undefined
      }
    >
      <Input.Search placeholder={t('common:search')} {...searchProps} />
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={{ position: 'bottom' }}
        rowKey="id"
        rowSelection={{
          type: singleSelect ? 'radio' : 'checkbox',
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
      />
    </Modal>
  );
}

SelectRulesModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
  audienceTypesToFilter: arrayOf(string),
};

SelectRulesModal.defaultProps = {
  audienceTypesToFilter: [],
};

export default SelectRulesModal;
