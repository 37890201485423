import React, { useCallback, useState } from 'react';
import { Icon, Popover, List, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import { logoWhite } from '@assets';
import {
  faUser,
  faFileAlt,
  faMailbox,
  faHandshakeAlt,
  faKey,
  faCog,
  // faDatabase,
  // faUserSlash,
  faFile,
  faGlobe,
} from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { procedurePathway } from '@assets/icons';
import { logout, goToPlatformApp } from '@redux/login/actions';
import { hasValidSession, selectCurrentDashboardUser } from '@redux/login/reducer';
import MenuItem from './MenuItem';
import LanguageSelector from './LanguageSelectorPopup';
import { Permissions } from '@authorisation/constants';
import SubMenuItem from './SubMenuItem';
import { useEvaluatePermissions } from '@authorisation/hooks';

const { confirm } = Modal;

export default () => {
  const currentDashboardUser = useSelector(selectCurrentDashboardUser);
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const doShowCarbon = useEvaluatePermissions(Permissions.AccessCarbon);
  const doShowUbiquity = useEvaluatePermissions(Permissions.AccessUbiquity);

  const handleVisibleChange = visible => {
    setVisible(visible);
  };

  const showLogOutConfirm = useCallback(() => {
    confirm({
      title: t('logout:title'),
      icon: <FontAwesomeIcon className="log-out-icon" icon={faExclamationCircle} />,
      okText: t('logout:ok'),
      onOk() {
        dispatch(logout());
        return waitForStoreUpdate(store, state => [hasValidSession(state)]);
      },
    });
  }, [dispatch, store, t]);

  return (
    <div className="menu">
      <div className="logo">
        <img src={logoWhite} alt="" />
      </div>
      <div className="menu-items">
        <MenuItem name="Home" path="/" icon={<Icon type="bank" />} exact>
          {t('common:Menu.home')}
        </MenuItem>
        <MenuItem
          name="App Users"
          path="/patients/individuals"
          icon={<FontAwesomeIcon icon={faUser} />}
          permissions={[Permissions.ViewPatients]}
        >
          {t('common:Menu.patients.title')}
        </MenuItem>
        <MenuItem
          name="Direct Messaging"
          path="/messages"
          permissions={[Permissions.ViewMessages]}
          icon={<FontAwesomeIcon icon={faMailbox} />}
          subMenuItems={[
            <SubMenuItem name="Direct Messaging Sent" path="/messages/sent">
              {t('common:Menu.messages.sent')}
            </SubMenuItem>,
            <SubMenuItem name="Direct Messaging Scheduled" path="/messages/scheduled">
              {t('common:Menu.messages.scheduled')}
            </SubMenuItem>,
          ]}
        >
          {t('common:Menu.messages.title')}
        </MenuItem>
        <MenuItem
          name="Content"
          path="/content"
          icon={<FontAwesomeIcon icon={faFileAlt} />}
          subMenuItems={[
            <SubMenuItem name="Documents" path="/content/documents">
              {t('common:Menu.content.documents')}
            </SubMenuItem>,
            <SubMenuItem name="Videos" path="/content/videos">
              {t('common:Menu.content.videos')}
            </SubMenuItem>,
            <SubMenuItem name="Forms" path="/content/forms">
              {t('common:Menu.content.forms')}
            </SubMenuItem>,
            <SubMenuItem name="Questionnaires" path="/content/questionnaires">
              {t('common:Menu.content.questionnaires')}
            </SubMenuItem>,
            <SubMenuItem name="Tasks" path="/content/tasks">
              {t('common:Menu.content.tasks')}
            </SubMenuItem>,
            <SubMenuItem name="Weblinks" path="/content/weblinks">
              {t('common:Menu.content.weblinks')}
            </SubMenuItem>,
            <SubMenuItem name="Messages" path="/content/messages">
              {t('common:Menu.content.messages')}
            </SubMenuItem>,
            <SubMenuItem name="Alerts" path="/content/alerts">
              {t('common:Menu.content.alerts')}
            </SubMenuItem>,
            <SubMenuItem name="Reminders" path="/content/reminders">
              {t('common:Menu.content.reminders')}
            </SubMenuItem>,
          ]}
        >
          {t('common:Menu.content.title')}
        </MenuItem>
        <MenuItem
          name="Pathways heading"
          path="/procedure"
          icon={<Icon component={procedurePathway} />}
          subMenuItems={[
            <SubMenuItem name="Pathways" path="/procedure/pathways">
              {t('common:Menu.pathway.pathways')}
            </SubMenuItem>,
            <SubMenuItem name="Rules" path="/procedure/rules">
              {t('common:Menu.pathway.rules')}
            </SubMenuItem>,
            <SubMenuItem name="SharedPathways" path="/procedure/shared-pathways">
              {t('common:Menu.pathway.sharedPathways')}
            </SubMenuItem>,
          ]}
        >
          {t('common:Menu.pathway.title')}
        </MenuItem>

        {/* <MenuItem
          icon={<Icon type="line-chart" />}
          active={pathname.startsWith('/review-hub')}
          onClick={() => trackMainMenu(history, '/review-hub', 'Review Hub heading')}
          subMenuItems={determineMetricsMenuItems()}
        >
          {t('common:Menu.reviewHub.title')}
        </MenuItem> */}

        <MenuItem
          name="Administration heading"
          path="/administration"
          icon={<FontAwesomeIcon icon={faCog} />}
          permissions={[Permissions.ViewAdministation]}
          subMenuItems={[
            <SubMenuItem
              name="Hospitals"
              path="/administration/organisations"
              permissions={[Permissions.AdministerAllHospitals]}
            >
              {t('common:Menu.administration.organisations')}
            </SubMenuItem>,
            <SubMenuItem
              name="Hospital"
              path="/administration/organisation"
              permissions={[Permissions.AdministerHospital]}
            >
              {t('common:Menu.administration.organisation')}
            </SubMenuItem>,
            <SubMenuItem
              name="Codes"
              path="/administration/codes"
              permissions={[Permissions.ManageConnectionCodes]}
            >
              {t('common:Menu.administration.codes')}
            </SubMenuItem>,
            <SubMenuItem
              name="Dashboard Users"
              path="/administration/dashboard-users"
              permissions={[Permissions.ViewDashboardUsers]}
            >
              {t('common:Menu.administration.dashboard-users')}
            </SubMenuItem>,
            <SubMenuItem
              name="Index Events"
              path="/administration/index-events"
              permissions={[Permissions.AdministerIndexEvents]}
            >
              {t('common:Menu.administration.index')}
            </SubMenuItem>,
            <SubMenuItem
              name="Data Requests"
              path="/administration/data-requests"
              permissions={[Permissions.ViewDataRequests]}
            >
              {t('common:Menu.administration.dataRequests')}
            </SubMenuItem>,
          ]}
        >
          {t('common:Menu.administration.title')}
        </MenuItem>
      </div>
      <Popover
        placement="rightBottom"
        title={t('common:Menu.popover.title')}
        trigger="hover"
        content={
          <List
            className="user-details-popover-list"
            size="small"
            dataSource={[
              {
                icon: <Icon type="lock" />,
                title: t('common:Menu.popover.logout'),
                onClick: () => showLogOutConfirm(),
              },
              {
                icon: <FontAwesomeIcon icon={faKey} />,
                title: t('common:Menu.popover.password'),
                onClick: () => history.push('/my-profile/change-password'),
              },
              {
                icon: <FontAwesomeIcon icon={faGlobe} />,
                title: t('common:Menu.popover.language'),
                rightIcon: <Icon type="right" className="change-language-menu" />,
                key: 'languages',
              },
              {
                icon: <FontAwesomeIcon icon={faHandshakeAlt} />,
                title: t('common:Menu.popover.termsnCondition'),
                onClick: () => history.push('/my-profile/terms-and-conditions'),
              },
              {
                icon: <FontAwesomeIcon icon={faFileAlt} />,
                title: t('common:Menu.popover.privacyPolicy'),
                onClick: () => history.push('/my-profile/privacy-policy'),
              },
              // Disable GDPR for staff in PEP for now as typically we have contracts with our customers to handle these concerns.
              // {
              //   icon: <FontAwesomeIcon icon={faDatabase} className="tab-item-icon" />,
              //   title: t('myProfile:Tabs.dataDownload'),
              //   onClick: () => history.push('/my-profile/gdpr-request-data'),
              // },
              // {
              //   icon: <FontAwesomeIcon icon={faUserSlash} className="tab-item-icon" />,
              //   title: t('myProfile:Tabs.dataDeletion'),
              //   onClick: () => history.push('/my-profile/gdpr-delete-data'),
              // },
              doShowCarbon && {
                icon: <FontAwesomeIcon icon={faFile} className="tab-item-icon" />,
                title: t('myProfile:Tabs.carbonEditor'),
                onClick: () => dispatch(goToPlatformApp('carbon')),
              },
              doShowUbiquity && {
                icon: <FontAwesomeIcon icon={faGlobe} className="tab-item-icon" />,
                title: t('myProfile:Tabs.ubiquityDashboard'),
                onClick: () => dispatch(goToPlatformApp('ubiquity')),
              },
            ].filter(item => item)}
            bordered={false}
            split={false}
            renderItem={item => {
              return item.key ? (
                <Popover
                  overlayClassName="change-languages"
                  content={<LanguageSelector />}
                  placement="rightBottom"
                  trigger="hover"
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <List.Item>
                    {item.icon} {item.title} {item.rightIcon}
                  </List.Item>
                </Popover>
              ) : (
                <List.Item onClick={item.onClick}>
                  {item.icon} {item.title} {item.rightIcon}
                </List.Item>
              );
            }}
          />
        }
      >
        <div className="user-details">
          <Icon type="lock" />
          <span className="name">{currentDashboardUser?.name}</span>
          <Icon type="right" />
        </div>
      </Popover>
    </div>
  );
};
