import { MESSAGE, ALERT, REMINDER } from '@utils/contentTypes';
import { IContentMessage } from './types';
import { selectDocument } from '../documents/reducers';
import { selectForm } from '../forms/reducers';
import { IState } from '../reducer';

export const selectContentMessagesCreating = (state: IState) => state.contentMessages.creating;
export const selectContentMessageDeleting = (state: IState) => [state.contentMessages.deleting];
export const selectMessagesLoading = (state: IState) => state.contentMessages.loading;
export const selectContentMessagePublishing = (state: IState) => state.contentMessages.publishing;
export const selectContentMessagesUpdating = (state: IState) => state.contentMessages.updating;

export const selectAllContentMessages = (state: IState) => [
  state.contentMessages.loading,
  state.contentMessages.list.map(id => state.contentMessages.byId[id]),
];

export const selectContentMessage = (messageId: string) => (
  state: IState,
): [boolean, IContentMessage | undefined] => [
  state.contentMessages.loading,
  state.contentMessages.byId[messageId],
];

// select only alerts
export const selectContentAlerts = (state: IState) => [
  state.contentMessages.loading,
  state.contentMessages.list
    .map(id => state.contentMessages.byId[id])
    .filter(alert => alert.latestVersion.metadata.type === ALERT),
];

// select only messages
export const selectContentMessages = (state: IState) => {
  const messages = state.contentMessages.list
    .map(id => state.contentMessages.byId[id])
    .filter(message => message.latestVersion?.metadata.type === MESSAGE);
  return [state.contentMessages.loading, messages];
};

// select reminders only
export const selectContentReminders = (state: IState) => {
  const reminders = state.contentMessages.list
    .map(id => state.contentMessages.byId[id])
    .filter(reminder => reminder.latestVersion.metadata.type === REMINDER);
  return [state.contentMessages.loading, reminders];
};

// canned message history not implemented yet at back end
export const selectContentMessageHistory = (messageId: string) => (state: IState) => [
  state.contentMessages.loading,
  // TODO: make the history for canned messages work
  [],
];

export const selectPayloadContent = (messagePayload: { type: string; id: string }) => (
  state: IState,
) => {
  if (!messagePayload) return '';
  const { type, id } = messagePayload;

  switch (type) {
    case 'DOC':
      const document = selectDocument(id)(state);
      return document;
    case 'FORM':
      const form = selectForm(id)(state);
      return form;
    default:
      return '';
  }
};
