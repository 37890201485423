import { combineReducers } from 'redux';
import rules from '@pathways/redux/rules/reducers';
import appUserPathways from '@pathways/redux/appUserPathways/reducers';
import pathways from '@pathways/redux/pathways/reducers';
import indexEvents from '@pathways/redux/indexEvents/reducers';
import reviewHub from '@reviewHub/redux/reducers';
import authorisation from '../authorisation/reducer';
import registration from './registration/reducer';
import login from './login/reducer';
import passwordRecovery from './passwordRecovery/reducer';
import onboarding from './onboarding/reducer';
import contentMessages from './contentMessages/reducers';
import documents from './documents/reducers';
import groups from './groups/reducer';
import appUsers from './appUsers/reducers';
import messages from './messages/reducers';
import forms from './forms/reducers';
import core from './core/reducers';
import myProfile from './myProfile/reducers';
import hospitals from './hospitals/reducers'; // todo: remove
import organisations from './organisations/reducers';
import termsOfUse from './termsOfUse/reducers';
import connectionCodes from './connectionCodes/reducers';
import dashboardUsers from './dashboardUsers/reducers';
import requests from './requests/reducer';
import organisation from '@organisation/redux/reducers';
import weblinks from './weblinks/reducers';
import sharedPathways from '@pathways/redux/sharedPathways/reducers';
import appTokens from './appTokens/reducers';

export type IState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  authorisation,
  appUserPathways,
  appUsers,
  contentMessages,
  core,
  connectionCodes,
  dashboardUsers,
  documents,
  forms,
  groups,
  hospitals, // todo: remove
  organisations,
  indexEvents,
  login,
  messages,
  myProfile,
  onboarding,
  organisation,
  passwordRecovery,
  pathways,
  registration,
  requests,
  reviewHub,
  rules,
  termsOfUse,
  weblinks,
  sharedPathways,
  appTokens,
});

export default rootReducer;
