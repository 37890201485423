import { IState } from '@redux/reducer';
import { WeblinkVersion } from '@liquid-state/ubiquity-client/dist/v2/types';

export const selectWeblinks = (state: IState) => [
  state.weblinks.loading,
  state.weblinks.list.map(id => state.weblinks.byId[id]),
];

export const selectWeblink = (weblinkId: string) => (state: IState) => [
  state.weblinks.loading,
  state.weblinks.byId[weblinkId],
];

export const selectWeblinkVersion = (weblinkId: string) => (
  state: IState,
): [boolean, WeblinkVersion] => [
  state.weblinks.latestVersion[weblinkId]?.loading ?? false,
  state.weblinks.latestVersion[weblinkId]?.data,
];

export const selectWeblinksCreating = (state: IState) => state.weblinks.creating;
export const selectWeblinksDeleting = (state: IState) => state.weblinks.deleting;
export const selectWeblinksEditing = (state: IState) => state.weblinks.editing;
