import { spawn, put } from 'redux-saga/effects';
import { faGlobe, faFlagAlt, faHospital } from '@fortawesome/pro-regular-svg-icons';
import core from './core/sagas';
import authorisation from '../authorisation/sagas';
import registration from './registration/saga';
import login from './login/saga';
import passwordRecovery from './passwordRecovery/saga';
import onboarding from './onboarding/saga';
import contentMessages from './contentMessages/sagas';
import documents from './documents/sagas';
import groups from './groups/saga';
import appUsers from './appUsers/sagas';
import messages from './messages/sagas';
import forms from './forms/sagas';
import myProfile from './myProfile/saga';
import hospitals from './hospitals/sagas'; // todo: remove
import organisations from './organisations/sagas';
import termsOfUse from './termsOfUse/saga';
import connectionCodes from './connectionCodes/sagas';
import dashboardUsers from './dashboardUsers/sagas';
import requests from './requests/saga';
import weblinks from './weblinks/sagas';
import indexEvents from '@pathways/redux/indexEvents/saga';
import appUserPathways from '@pathways/redux/appUserPathways/sagas';
import pathways from '@pathways/redux/pathways/sagas';
import rules from '@pathways/redux/rules/sagas';
import reviewHub from '@reviewHub/redux/sagas';
import languages from '@utils/supportedLanguages';
import sharedPathways from '@pathways/redux/sharedPathways/sagas';
import { setLanguages, setOwners } from './core/actions';

export default function*() {
  yield spawn(core);
  yield spawn(authorisation);
  yield spawn(registration);
  yield spawn(login);
  yield spawn(passwordRecovery);
  yield spawn(onboarding);
  yield spawn(contentMessages);
  yield spawn(documents);
  yield spawn(groups);
  yield spawn(appUsers);
  yield spawn(appUserPathways);
  yield spawn(messages);
  yield spawn(forms);
  yield spawn(pathways);
  yield spawn(rules);
  yield spawn(indexEvents);
  yield spawn(myProfile);
  yield spawn(hospitals); // todo: remove
  yield spawn(organisations);
  yield spawn(termsOfUse);
  yield spawn(configureDashboard);
  yield spawn(connectionCodes);
  yield spawn(dashboardUsers);
  yield spawn(requests);
  yield spawn(reviewHub);
  yield spawn(weblinks);
  yield spawn(sharedPathways);
}

function* configureDashboard() {
  yield put(setLanguages(Object.values(languages)));
  yield put(
    setOwners([
      {
        key: 'global',
        name: 'common:source.global',
        icon: faGlobe,
      },
      {
        key: 'country',
        name: 'common:source.country',
        icon: faFlagAlt,
      },
      {
        key: 'hospital',
        name: 'common:source.hospital',
        icon: faHospital,
      },
    ]),
  );
}
