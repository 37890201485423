import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { determineSourceTypeForNewContent } from '@utils';
import { editVideo, fetchDocuments, fetchDocumentVersions } from '@redux/documents/actions';
import { selectDocument, selectDocumentEditing } from '@redux/documents/reducers';
import { selectLanguage } from '@redux/core/reducers';
import { languageFromMetadata } from '@utils/contentMetadata';
import { usePageActions } from '@hooks/usePageActions';
import { matchType } from '../../../propTypes';
import VideoWizard from './index';

const nowMoment = moment();

function EditVideo({ match }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // since video is part of document, document data object is used here.
  const [loading, document] = useSelector(selectDocument(match.params.id));
  const permissions = useSelector(selectPermissionsForUser);
  const editing = useSelector(selectDocumentEditing);
  const findLanguage = useSelector(languageFromMetadata);
  const language = useSelector(selectLanguage(findLanguage(document)));
  const [initialData, setInitialData] = useState({
    content: {
      title: undefined,
      videoDescription: undefined,
      link: undefined,
    },
    details: {
      name: undefined,
      description: undefined,
      language: undefined,
    },
  });

  const { setTitle } = usePageActions({
    title: '',
    showClose: true,
  });

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchDocumentVersions(match.params.id));
  }, [dispatch, match]);

  //fields in content should be changed depending on the document data object returned. Right now those are placeholders. Populating the fields with the data object values
  useEffect(() => {
    if (document) {
      setTitle(`${t('videos:Wizard.edit')} ${document.name}`);
      let title = '';
      try {
        title = document.metadata.custom.content.heading;
      } catch (e) {}
      let videoDescription = '';
      try {
        videoDescription = document.metadata.custom.content.description;
      } catch (e) {}
      let link = '';
      try {
        link = `https://www.youtube.com/embed/${document.metadata.custom.content.video_id}`;
      } catch (e) {}
      let publishDate = '';
      try {
        publishDate = document.metadata.custom.content.publishDate;
      } catch (e) {}
      let runTime = '';
      try {
        runTime = document.metadata.custom.content.runTime;
      } catch (e) {}
      setInitialData({
        content: {
          title,
          videoDescription,
          link,
          publishDate,
          runTime,
        },
        details: {
          name: document.name,
          description: document.description,
          language: language?.key,
          latestVersion: document.latest_version.name,
          audience: document.metadata?.tags?.find(tag => tag.label === 'AUDIENCE')?.term,
        },
      });
    }
  }, [document, language, setTitle, t]);

  const getVideoId = link => {
    const videoId = link.slice(-11);
    return videoId;
  };

  const onCompleted = useCallback(
    formData => {
      if (formData) {
        const { content, details } = formData;

        const editedVideo = {
          description: details.description,
          latest_version: { created: nowMoment.toISOString(), name: details.name },
          published: details.status === 'published',
          name: details.name,
          metadata: {
            language: details.language || 'en',
            source: determineSourceTypeForNewContent(permissions),
            audience: details.audience,
          },
          videoData: {
            videoTitle: content.title,
            videoDescription: content.videoDescription,
            videoId: getVideoId(content.link),
            publishDate: content.publishDate,
            runTime: content.runTime,
          },
        };

        dispatch(
          editVideo(
            document.latest_version?.metadata?.readonly?.ls?.carbon?.uuid,
            match.params.id,
            editedVideo,
          ),
        );
      }
    },
    [dispatch, match, document, permissions],
  );

  return (
    <VideoWizard
      loading={loading}
      submitting={editing}
      initialData={initialData}
      onCompleted={onCompleted}
      isEdit={true}
    />
  );
}

EditVideo.propTypes = {
  match: matchType.isRequired,
};

export default EditVideo;
