import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadContent } from '@redux/core/actions';
import { AnyContentMap, selectContent } from '@redux/core/reducers';

const useContentRefs = (refs?: string[]) => {
  const dispatch = useDispatch();
  const [cachedMap, setCachedMap] = useState<AnyContentMap>({});

  // Extracting useEffect deps as requested by react-hooks/exhaustive-deps
  const refsCount = refs?.length ?? 0;

  useEffect(() => {
    dispatch(loadContent(refs ?? []));
    // Don't include refs as a dependency to avoid re-running the
    // effect when the array is updated and depend on refsCount instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refsCount, dispatch]);

  const content = useSelector(selectContent(refs ?? []));
  const contentCount = Object.keys(content).length;

  useEffect(() => {
    setCachedMap(content);
    // Don't include content as a dependency to avoid re-running the
    // effect when the array is updated and depend on contentCount instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentCount, refsCount]);

  return cachedMap;
};

export default useContentRefs;
