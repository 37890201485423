import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, arrayOf, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fetchDocuments } from '@redux/documents/actions';
import { fetchForms } from '@redux/forms/actions';
import SingleSelectModal from '../SingleSelectModal';
import createColumns from './createColumns';
import { selectContent, filterFunc } from './';
import { WEBLINK, WEBSITE, WEB_FORM } from '@utils/contentTypes';
import { fetchWeblinks } from '@redux/weblinks/actions';

function SelectContentModal({
  selectedContent,
  contentTypes,
  defaultFilteredValue,
  visible,
  onCancel,
  onSubmit,
  customContent,
  modalTitle,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, allContent] = useSelector(selectContent);

  const actualContent = useMemo(() => {
    if (customContent) {
      return customContent.filter(c =>
        c.metadata?.type === 'MESSAGE'
          ? contentTypes.includes('MESSAGE')
          : contentTypes.includes(c.metadata?.type),
      );
    }
    return allContent.filter(c => contentTypes.includes(c.metadata.type));
  }, [allContent, contentTypes, customContent]);

  const columns = useMemo(() => {
    return createColumns(t, contentTypes, defaultFilteredValue);
  }, [t, contentTypes, defaultFilteredValue]);

  useEffect(() => {
    if (!customContent) {
      if (contentTypes.includes('DOCUMENT') || contentTypes.includes('VIDEO')) {
        dispatch(fetchDocuments());
      }
      if (
        contentTypes.includes('FORM') ||
        contentTypes.includes('QUESTIONNAIRE') ||
        contentTypes.includes('TASK')
      ) {
        dispatch(fetchForms());
      }
      if (
        contentTypes.includes(WEBLINK) ||
        contentTypes.includes(WEBSITE) ||
        contentTypes.includes(WEB_FORM)
      ) {
        dispatch(fetchWeblinks());
      }
    }
  }, [dispatch, contentTypes, customContent]);

  return (
    <SingleSelectModal
      selected={selectedContent}
      columns={columns}
      dataSource={actualContent}
      displayKeys={['title']}
      filterFunc={filterFunc}
      loading={loading}
      primaryKey="id"
      title={modalTitle || t('common:modals.SelectContent.title')}
      visible={visible}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}

SelectContentModal.propTypes = {
  contentTypes: arrayOf(string).isRequired,
  defaultFilteredValue: shape({}),
  visible: bool.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  customContent: arrayOf(shape({})),
  modalTitle: string,
};

SelectContentModal.defaultProps = {
  defaultFilteredValue: {},
  customContent: null,
  modalTitle: '',
};

export default SelectContentModal;
