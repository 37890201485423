import { combineReducers } from 'redux';
import { IContentMessage } from './types';
import {
  ILoadContentMessages,
  IContentMessagesLoaded,
  ICreateContentMessage,
  ICreateContentMessageSuccess,
  ICreateContentMessageFailed,
  IUpdateContentMessage,
  IUpdateContentMessageSuccess,
  IUpdateContentMessageFailed,
  IDeleteContentMessages,
  IDeleteContentMessagesSuccess,
  IDeleteContentMessagesFailed,
  IPublishContentMessage,
  IPublishContentMessageFailed,
  IPublishContentMessageSuccess,
  IUnpublishContentMessage,
  IUnpublishContentMessageFailed,
  IUnpublishContentMessageSuccess,
} from './actions';
import { ILoadContentSuccess } from '@redux/core/actions';

function byId(
  state: { [key: string]: IContentMessage } = {},
  action:
    | IContentMessagesLoaded
    | IDeleteContentMessagesSuccess
    | ICreateContentMessageSuccess
    | IPublishContentMessageSuccess
    | IUnpublishContentMessageSuccess
    | IUpdateContentMessageSuccess
    | ILoadContentSuccess,
): { [key: string]: IContentMessage } {
  switch (action.type) {
    case 'content-messages/loaded':
      return action.payload.messages.reduce(
        (messagesById, message) => ({ ...messagesById, [message.id]: message }),
        state,
      );
    case 'content-messages/delete-success':
      return Object.entries(state).reduce(
        (messagesById, [id, message]) =>
          action.payload.messageIds.includes(id)
            ? messagesById
            : { ...messagesById, [id]: message },
        {},
      );
    case 'content-messages/create-success':
    case 'content-messages/update-success':
      return { ...state, [action.payload.message.id]: action.payload.message };
    case 'content-messages/publish-success':
    case 'content-messages/unpublish-success':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          published: !state[action.payload.id].published,
        },
      };
    case 'core/load-content-success':
      return {
        ...state,
        ...action.payload.messages,
      };
    default:
      return state;
  }
}

const creating = (
  state: boolean = false,
  action: ICreateContentMessage | ICreateContentMessageSuccess | ICreateContentMessageFailed,
): boolean => {
  switch (action.type) {
    case 'content-messages/create':
      return true;
    case 'content-messages/create-success':
    case 'content-messages/create-failed':
      return false;
    default:
      return state;
  }
};

const deleting = (
  state: boolean = false,
  action: IDeleteContentMessages | IDeleteContentMessagesSuccess | IDeleteContentMessagesFailed,
): boolean => {
  switch (action.type) {
    case 'content-messages/delete':
      return true;
    case 'content-messages/delete-failed':
    case 'content-messages/delete-success':
      return false;
    default:
      return state;
  }
};

function list(
  state: string[] = [],
  action: IContentMessagesLoaded | IDeleteContentMessagesSuccess | ICreateContentMessageSuccess,
) {
  switch (action.type) {
    case 'content-messages/loaded':
      return action.payload.messages.map(({ id }) => id);
    case 'content-messages/delete-success':
      return state.filter(id => !action.payload.messageIds.includes(id));
    case 'content-messages/create-success':
      return [action.payload.message.id, ...state];
    default:
      return state;
  }
}

const loading = (state = false, action: ILoadContentMessages | IContentMessagesLoaded): boolean => {
  switch (action.type) {
    case 'content-messages/load':
      return true;
    case 'content-messages/loaded':
      return false;
    default:
      return state;
  }
};

function publishing(
  state: boolean = false,
  action:
    | IPublishContentMessage
    | IPublishContentMessageFailed
    | IPublishContentMessageSuccess
    | IUnpublishContentMessage
    | IUnpublishContentMessageFailed
    | IUnpublishContentMessageSuccess,
) {
  switch (action.type) {
    case 'content-messages/publish':
    case 'content-messages/unpublish':
      return true;
    case 'content-messages/publish-failed':
    case 'content-messages/publish-success':
    case 'content-messages/unpublish-failed':
    case 'content-messages/unpublish-success':
      return false;
    default:
      return state;
  }
}

const updating = (
  state: boolean = false,
  action: IUpdateContentMessage | IUpdateContentMessageSuccess | IUpdateContentMessageFailed,
): boolean => {
  switch (action.type) {
    case 'content-messages/update':
      return true;
    case 'content-messages/update-success':
    case 'content-messages/update-failed':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  creating,
  deleting,
  list,
  loading,
  publishing,
  updating,
});
