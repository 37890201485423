import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Timeline, Popover, Button } from 'antd';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import DocumentPreview from '@components/DocumentPreview';
import WeblinkPreview from '@components/WeblinkPreview';
import IconButton from '@components/IconButton';
import {
  selectPathwaysForAppUserWithOriginals,
  selectJourneysForAppUser,
} from '@pathways/redux/appUserPathways/reducers';
import { selectAppUserForms } from '@redux/appUsers/reducers';
import { selectForms } from '@redux/forms/reducers';
import { MESSAGE, whatTypeIcons, FEATURE_DOCUMENT, FEATURE_VIDEO } from '@constants';
import { formTypes, WEBSITE, WEB_FORM, WEBLINK } from '@utils/contentTypes';
import { SubmitFormModals, VISIBLE_MODAL } from '@cards/AppUsers/SubmitForm';
import { Column, ColumnGroup, Card } from '@cards/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { fetchRules, fetchRulesWhatDetail } from '@pathways/redux/rules/actions';
import { selectRule } from '@pathways/redux/rules/reducers';
import { fetchForms } from '@redux/forms/actions';
import { fetchAppUsersForms } from '@redux/appUsers/actions';
import { actionJourneyEntry } from '@pathways/redux/appUserPathways/actions';
import Text from 'antd/lib/typography/Text';

import './styles.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const weblinkTypes = [WEBSITE, WEB_FORM, WEBLINK];
const documentTypes = [FEATURE_DOCUMENT, FEATURE_VIDEO, 'document'];

function ClinicalChecklistCard({ appUser }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [ruleId, setRuleId] = useState();
  const [selectedContent, setSelectedContent] = useState();
  const [selectedContentOriginalPathwayId, setSelectedContentOriginalPathwayId] = useState();
  const [selectedContentStageSlug, setSelectedContentStageSlug] = useState();
  const [documentPreviewVisible, setDocumentPreviewVisible] = useState(false);
  const [weblinkPreviewVisible, setWeblinkPreviewVisible] = useState(false);
  const [formType, setFormType] = useState();
  const [submitFormVisible, setSubmitFormVisible] = useState();
  const [formReadOnly, setFormReadOnly] = useState(false);
  const [selectedContentJourneyEntry, setSelectedContentJourneyEntry] = useState();

  const [appUserPathwaysLoading, appUserPathways] = useSelector(
    selectPathwaysForAppUserWithOriginals(appUser),
  );
  const [appUserJourneysLoading, appUserJourneys] = useSelector(selectJourneysForAppUser(appUser));
  const [rulesLoading, rule] = useSelector(selectRule(ruleId));
  const [, forms] = useSelector(selectForms);

  const appUserFormsSelector = useCallback(selectAppUserForms(appUser?.id, forms), [
    appUser?.id,
    forms,
  ]);
  const [, formSubmissions] = useSelector(appUserFormsSelector);

  const appUserJourneyEntries = useMemo(() => {
    return appUserJourneys?.flatMap(appUserJourney =>
      appUserJourney.entries.map(entry => ({
        ...entry,
        journeyId: appUserJourney.id,
      })),
    );
  }, [appUserJourneys]);

  const isClinicianContentActionableOrActioned = useCallback(
    (rule, pathwayId, stageSlug) => {
      const correspondingJourneyEntry = appUserJourneyEntries?.find(
        entry =>
          entry.data?.original_rule_id === rule.id &&
          entry.data?.original_pathway_id === pathwayId &&
          entry.data?.execution_details.current_stage_slug === stageSlug,
      );
      if (correspondingJourneyEntry) {
        return correspondingJourneyEntry.is_actioned ? 'actioned' : 'actionable';
      } else {
        return 'not-actionable';
      }
    },
    [appUserJourneyEntries],
  );

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchAppUsersForms(appUser?.id));
    dispatch(fetchRules());
  }, [dispatch, appUser]);

  useEffect(() => {
    if (!rulesLoading && selectedContent) {
      setRuleId(selectedContent.id);
      if (rule) {
        const ruleActionedStatus = isClinicianContentActionableOrActioned(
          rule,
          selectedContentOriginalPathwayId,
          selectedContentStageSlug,
        );

        const contentType =
          rule.whatDetail.type ||
          rule.whatDetail.metadata?.type ||
          rule.whatDetail.content_type ||
          rule.whatDetail.content.split('/')[1];

        if (documentTypes.includes(contentType)) {
          setDocumentPreviewVisible(true);
        } else if (weblinkTypes.includes(contentType)) {
          setWeblinkPreviewVisible(true);
        } else if (formTypes.includes(contentType)) {
          const formSubmission = formSubmissions.find(
            formSubmission => formSubmission.submission.formId === rule.whatDetail.uuid,
          );
          if (ruleActionedStatus === 'actioned' && !submitFormVisible === VISIBLE_MODAL.SUBMIT) {
            history.push(
              `/patients/individuals/${
                appUser?.id
              }/${rule.whatDetail.metadata?.type.toLowerCase()}s/${rule.whatDetail.uuid}/${
                formSubmission.submission.uuid
              }`,
            );
          } else {
            setFormType(contentType);
            setSubmitFormVisible(VISIBLE_MODAL.SUBMIT);
          }
        }
      }
    }
  }, [
    rulesLoading,
    selectedContent,
    rule,
    history,
    appUser,
    submitFormVisible,
    formSubmissions,
    isClinicianContentActionableOrActioned,
    selectedContentOriginalPathwayId,
    selectedContentStageSlug,
  ]);

  const handleContentClick = useCallback(
    (rule, originalPathwayId, stageSlug) => {
      dispatch(fetchRulesWhatDetail([{ rule }]));
      setSelectedContent(rule);
      setSelectedContentOriginalPathwayId(originalPathwayId);
      setSelectedContentStageSlug(stageSlug);
      const correspondingJourneyEntry = appUserJourneyEntries?.find(
        entry =>
          entry.data?.original_rule_id === rule.id &&
          entry.data?.original_pathway_id === originalPathwayId &&
          entry.data?.execution_details.current_stage_slug === stageSlug,
      );
      const contentType =
        rule.whatDetail.type || rule.whatDetail.metadata?.type || rule.whatDetail.content_type;

      if (correspondingJourneyEntry) setSelectedContentJourneyEntry(correspondingJourneyEntry);

      const ruleActionedStatus = isClinicianContentActionableOrActioned(
        rule,
        originalPathwayId,
        stageSlug,
      );
      if (!formTypes.includes(contentType)) {
        if (ruleActionedStatus === 'actionable') {
          dispatch(
            actionJourneyEntry(
              appUser?.id,
              correspondingJourneyEntry?.journeyId,
              correspondingJourneyEntry?.id,
            ),
          );
        }
      } else {
        if (ruleActionedStatus === 'not-actionable') {
          setFormReadOnly(true);
        } else {
          setFormReadOnly(false);
        }
      }
    },
    [dispatch, appUserJourneyEntries, appUser, isClinicianContentActionableOrActioned],
  );

  const appUserOriginalPathwaysStagesWithClinicianRules = useMemo(() => {
    return (
      appUserPathways?.map(pathway => {
        const { originalPathway, currentStageSlug, journeyId } = pathway;
        const { id, name, stages } = originalPathway;

        const currentStageIndex = stages.findIndex(stage => stage.slug === currentStageSlug);

        const transformedStages = stages.map((stage, index) => ({
          ...stage,
          isStageCompletedOrCurrent: index <= currentStageIndex,
          rules: stage.rules.filter(
            rule => rule.audienceType === 'clinician' && rule.whatDetail.metadata?.type !== MESSAGE,
          ),
        }));

        return {
          id,
          name,
          stages: transformedStages,
          currentStageSlug,
          journeyId,
        };
      }) || []
    );
  }, [appUserPathways]);

  const icon = useCallback(
    (stage, rule, pathwayId) => {
      if (!stage || !rule) return <></>;

      const ruleActionedStatus = isClinicianContentActionableOrActioned(
        rule,
        pathwayId,
        stage.slug,
      );

      if (!stage.isStageCompletedOrCurrent && !stage.slug.includes('non-alignedtoastage')) {
        return (
          <IconButton
            icon={faInfoCircle}
            tooltip={t('cards:ClinicalChecklist.notActionable')}
            onClick={() => {}}
          />
        );
      } else if (ruleActionedStatus === 'not-actionable') {
        return (
          <IconButton
            icon={faInfoCircle}
            tooltip={t('cards:ClinicalChecklist.notExecuted')}
            onClick={() => {}}
          />
        );
      } else if (ruleActionedStatus === 'actionable') {
        return <FontAwesomeIcon size="lg" className="not-actioned-icon" icon={faCircle} />;
      } else {
        return <FontAwesomeIcon size="lg" className="actioned-icon" icon={faCheckCircle} />;
      }
    },
    [t, isClinicianContentActionableOrActioned],
  );

  const renderClinicalChecklistCards = useMemo(() => {
    if (!appUserOriginalPathwaysStagesWithClinicianRules?.length) {
      return null;
    }

    return Array(Math.ceil(appUserOriginalPathwaysStagesWithClinicianRules.length / 2))
      .fill()
      .map((_, groupIndex) => (
        <ColumnGroup key={`group-${groupIndex}`}>
          {[0, 1].map(columnIndex => {
            const itemIndex = groupIndex * 2 + columnIndex;
            return (
              <Column key={`column-${groupIndex}-${columnIndex}`}>
                {itemIndex < appUserOriginalPathwaysStagesWithClinicianRules.length &&
                  appUserOriginalPathwaysStagesWithClinicianRules[itemIndex]?.stages.flatMap(
                    stage => stage.rules,
                  )?.length > 0 && (
                    <Card.Half
                      className="clinical-checklist-card"
                      noPadding
                      loading={appUserPathwaysLoading || appUserJourneysLoading || rulesLoading}
                      title={
                        <div>
                          {t('cards:ClinicalChecklist.title')}
                          <Popover
                            content={t('cards:ClinicalChecklist.tooltip')}
                            title={t('cards:ClinicalChecklist.tooltipTitle')}
                            trigger="click"
                            placement="bottom"
                            overlayClassName="clinical-checklist-popover"
                          >
                            <IconButton
                              icon={faInfoCircle}
                              tooltip={t('cards:ClinicalChecklist.tooltipTitle')}
                              onClick={() => {}}
                            />
                          </Popover>
                        </div>
                      }
                    >
                      <Form {...formItemLayout} className="detail-form">
                        {appUserOriginalPathwaysStagesWithClinicianRules[itemIndex].name && (
                          <h3>
                            {t('cards:ClinicalChecklist.subtitle', {
                              pathwayName:
                                appUserOriginalPathwaysStagesWithClinicianRules[itemIndex].name,
                            })}
                          </h3>
                        )}
                        <>
                          <div className="progress-bar">
                            <div
                              className="progress-bar-fill"
                              style={{
                                width: `${(appUserOriginalPathwaysStagesWithClinicianRules[
                                  itemIndex
                                ].stages.filter(stage => stage.isStageCompletedOrCurrent).length /
                                  (appUserOriginalPathwaysStagesWithClinicianRules[itemIndex].stages
                                    .length -
                                    1)) *
                                  100}%`,
                              }}
                            />
                          </div>
                          <br />

                          <Timeline>
                            {appUserOriginalPathwaysStagesWithClinicianRules[itemIndex].stages.map(
                              stage => (
                                <Timeline.Item
                                  className={`${
                                    stage.isStageCompletedOrCurrent
                                      ? 'completed-or-current-stage'
                                      : ''
                                  }`}
                                  key={stage.name}
                                >
                                  <Text strong>{stage.name}</Text>
                                  {stage.rules.map(rule => (
                                    <div className="content-container" key={rule.id}>
                                      <div className="content-icon-name-container">
                                        {whatTypeIcons[rule.what] && (
                                          <FontAwesomeIcon icon={whatTypeIcons[rule.what]} />
                                        )}
                                        <Text>{rule.whatDetail.name}</Text>
                                      </div>
                                      <div className="action-container">
                                        {icon(
                                          stage,
                                          rule,
                                          appUserOriginalPathwaysStagesWithClinicianRules[itemIndex]
                                            .id,
                                        )}
                                        <Button
                                          onClick={() =>
                                            handleContentClick(
                                              rule,
                                              appUserOriginalPathwaysStagesWithClinicianRules[
                                                itemIndex
                                              ].id,
                                              stage.slug,
                                            )
                                          }
                                        >
                                          {t('cards:ClinicalChecklist.view')}
                                        </Button>
                                      </div>
                                    </div>
                                  ))}
                                </Timeline.Item>
                              ),
                            )}
                          </Timeline>
                        </>
                      </Form>
                    </Card.Half>
                  )}
              </Column>
            );
          })}
        </ColumnGroup>
      ));
  }, [
    appUserOriginalPathwaysStagesWithClinicianRules,
    appUserPathwaysLoading,
    appUserJourneysLoading,
    icon,
    t,
    handleContentClick,
    rulesLoading,
  ]);

  return (
    <>
      {renderClinicalChecklistCards}

      {formType &&
        formTypes.includes(rule?.whatDetail?.type || rule?.whatDetail?.metadata?.type) && (
          <SubmitFormModals
            appUserId={appUser?.id}
            formType={formType}
            visibleModal={submitFormVisible}
            setVisibleModal={setSubmitFormVisible}
            selectedForm={rule?.whatDetail}
            setSelectedForm={setSelectedContent}
            type={formType}
            readOnly={formReadOnly}
            journeyEntry={selectedContentJourneyEntry}
            setSelectedContent={setSelectedContent}
          />
        )}
      {(rule?.whatDetail?.content?.split('/')?.[1] === 'document' ||
        documentTypes.includes(rule?.whatDetail?.content_type)) && (
        <DocumentPreview
          document={rule?.whatDetail}
          visible={documentPreviewVisible}
          onCancel={() => setDocumentPreviewVisible(false)}
        />
      )}
      {weblinkTypes.includes(rule?.whatDetail?.type || rule?.whatDetail?.metadata?.type) && (
        <WeblinkPreview
          weblink={rule?.whatDetail}
          visible={weblinkPreviewVisible}
          onCancel={() => setWeblinkPreviewVisible(false)}
        />
      )}
    </>
  );
}

export default ClinicalChecklistCard;
