import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, arrayOf, number, oneOfType, string, shape } from 'prop-types';
import { Button, Modal, Input, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { WEBLINK, WEBSITE, WEB_FORM, documentTypes } from '@utils/contentTypes';
import { MESSAGE, MESSAGE_ALERT, MESSAGE_REMINDER } from '@constants';
import { fetchDocuments } from '@redux/documents/actions';
import { fetchForms } from '@redux/forms/actions';
import { fetchWeblinks } from '@redux/weblinks/actions';
import { fetchMessages } from '@redux/messages/actions';
import { useSearch } from '@hooks';
import createColumns from './createColumns';
import { selectContent, filterFunc } from './';

function SelectContentModal({
  contentTypes,
  contentIdsToFilter,
  visible,
  onCancel,
  onSubmit,
  publishedOnly,
  customContent,
  existingSelectedRowKeys,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, allContent] = useSelector(selectContent);

  const contentSource = useMemo(() => {
    if (customContent) {
      return customContent;
    }

    const filterContent = c =>
      (!contentTypes || contentTypes.includes(c.metadata?.type)) &&
      ![c.product_id, c.id, c.uuid].some(id => contentIdsToFilter.includes(id));

    return allContent.filter(filterContent);
  }, [allContent, contentTypes, contentIdsToFilter, customContent]);

  const content = useMemo(() => {
    if (customContent && !publishedOnly) {
      return customContent;
    }

    if (publishedOnly) {
      return contentSource.filter(c => {
        if (documentTypes.includes(c.metadata?.type)) {
          return c.published;
        }
        return c.metadata.published;
      });
    }
    return contentSource;
  }, [contentSource, publishedOnly, customContent]);

  const columns = useMemo(() => {
    if (publishedOnly) {
      return createColumns(t, contentTypes).slice(0, -1);
    }
    return createColumns(t, contentTypes);
  }, [t, contentTypes, publishedOnly]);

  useEffect(() => {
    if (!customContent && contentTypes) {
      if (contentTypes.includes('DOCUMENT') || contentTypes.includes('VIDEO')) {
        dispatch(fetchDocuments());
      }
      if (
        contentTypes.includes('FORM') ||
        contentTypes.includes('QUESTIONNAIRE') ||
        contentTypes.includes('TASK')
      ) {
        dispatch(fetchForms());
      }
      if (
        contentTypes.includes(MESSAGE) ||
        contentTypes.includes(MESSAGE_ALERT) ||
        contentTypes.includes(MESSAGE_REMINDER)
      ) {
        dispatch(fetchMessages());
      }
      if (
        contentTypes.includes(WEBLINK) ||
        contentTypes.includes(WEBSITE) ||
        contentTypes.includes(WEB_FORM)
      ) {
        dispatch(fetchWeblinks());
      }
    }
  }, [dispatch, contentTypes, customContent]);

  const [selectedRowKeys, setSelectedRowKeys] = useState(existingSelectedRowKeys || []);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchResults, searchProps] = useSearch(content, filterFunc);

  const updateRowSelections = useCallback(
    (keys, records) => {
      setSelectedRowKeys(keys);
      setSelectedRows(records);
    },
    [setSelectedRowKeys],
  );

  useEffect(() => {
    if (existingSelectedRowKeys?.length > 0) {
      const matchingRows = content.filter(c =>
        existingSelectedRowKeys.includes(c.whatDetail?.uuid || c.whatDetail?.id || c.uuid || c.id),
      );
      setSelectedRows(matchingRows);
    }
  }, [content, existingSelectedRowKeys]);

  return (
    <Modal
      afterClose={() => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
      }}
      title={t('common:modals.SelectContent.title')}
      visible={visible}
      onCancel={onCancel}
      width={800}
      footer={[
        <div className="footer-left" key="footer-button-select-all"></div>,
        <div className="footer-right" key="footer-button-add">
          <Button
            disabled={!selectedRowKeys || !selectedRowKeys.length}
            type="primary"
            onClick={() => {
              onSubmit(selectedRowKeys, selectedRows); // preserve order plz
            }}
          >
            {t('common:selectionModal.add')}
          </Button>
        </div>,
      ]}
      className="selection-modal"
    >
      <div className="modal-search">
        <div className="row-key-count">{selectedRowKeys ? selectedRowKeys.length : 0}</div>
        <Input.Search placeholder={t('common:search')} {...searchProps}></Input.Search>
      </div>
      <Table
        columns={columns}
        dataSource={searchResults}
        rowKey={record => record.id || record.uuid}
        loading={loading}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: updateRowSelections,
        }}
        pagination={{ position: 'bottom' }}
      />
    </Modal>
  );
}

SelectContentModal.propTypes = {
  contentTypes: arrayOf(string).isRequired,
  contentIdsToFilter: arrayOf(oneOfType([number, string])),
  visible: bool,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  customContent: arrayOf(shape({})),
};

SelectContentModal.defaultProps = {
  contentIdsToFilter: [],
  visible: false,
  customContent: null,
};

export default SelectContentModal;
