import moment from 'moment';
import DateFilter from '@components/DateFilter';

export default t => [
  {
    title: t('cards:PatientFormList.columns.date'),
    key: 'date',
    render: data => moment(data.submission.created).format('lll'),
    width: 200,
    ellipsis: true,
    sorter: (a, b) => a.submission.created.localeCompare(b.submission.created),
    filterDropdown: DateFilter.filterDropdown,
    onFilter: (value, record) => DateFilter.onFilter(value, record.submission.created),
  },
  {
    title: t('cards:PatientFormList.columns.title'),
    key: 'title',
    render: data => data.name,
    ellipsis: true,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: t('cards:PatientFormList.columns.audience'),
    key: 'source',
    render: data =>
      data.audienceType ? t(`cards:PatientFormList.columns.audiences.${data.audienceType}`) : '',
    ellipsis: true,
    sorter: (a, b) => a.audienceType.localeCompare(b.audienceType),
    onFilter: (value, record) => record.audienceType === value,
    filters: [
      {
        text: t('cards:PatientFormList.columns.audiences.app-user'),
        value: 'app-user',
      },
      {
        text: t('cards:PatientFormList.columns.audiences.clinician'),
        value: 'clinician',
      },
      {
        text: t('cards:PatientFormList.columns.audiences.patient-admin'),
        value: 'patient-admin',
      },
    ],
  },
];
