import React from 'react';
import { createLanguageFilters } from '@utils/supportedLanguages';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { trimTextToApproximateLength } from '@utils';

export default (t, formTranslationKey) => [
  {
    className: 'language-column',
    title: t('cards:FormList.columns.language.title'),
    dataIndex: 'language',
    key: 'language',
    render: language => {
      return <LanguageIcon language={language} />;
    },
    filters: createLanguageFilters(t),
    onFilter: (value, record) => {
      return record.language === value;
    },
    width: 125,
  },
  {
    title: t(`cards:FormList.columns.${formTranslationKey}.title`),
    dataIndex: 'name',
    key: 'title',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: t('cards:FormList.columns.description'),
    dataIndex: 'description',
    key: 'description',
    render: description => trimTextToApproximateLength(description),
    sorter: (a, b) => a.description.localeCompare(b.description),
  },
  {
    className: 'published-column',
    title: t('cards:FormList.columns.published.title'),
    dataIndex: 'published',
    key: 'published',
    render: published => <PublishedStatusIcon published={published} />,
    filters: [
      {
        text: t('cards:FormList.columns.published.published'),
        value: true,
      },
      {
        text: t('cards:FormList.columns.published.unpublished'),
        value: false,
      },
    ],
    onFilter: (value, record) => record.published === value,
  },
];
