import React, { useState, useEffect, useMemo, cloneElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Pagination } from 'antd';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { fetchAppUserEngagements } from '@redux/appUsers/actions';
import { selectAppUserEngagements } from '@redux/appUsers/reducers';
import { fetchAppUserJourneys } from '@pathways/redux/appUserPathways/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import { selectJourneysForAppUser } from '@pathways/redux/appUserPathways/reducers';
import { selectContentTypeIcon } from '@assets/icons';
import { historyType, matchType } from '../../propTypes';
import { MESSAGE } from '@constants';
import { getBasicContentDetails } from '../../utils/contentDetails';

import './style.less';
import useContentRefs from '@hooks/useContentRefs';

const filterFunc = (value, comparator) => comparator(value.content_name);

function AppUserEngagements({ history, match }) {
  const appUserId = match.params.id;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { engagements, loading } = useSelector(selectAppUserEngagements(appUserId));
  const [, appUser] = useSelector(selectAppUser(appUserId));
  const [, journeys] = useSelector(selectJourneysForAppUser(appUser));
  const [engagementsWithNames, setEngagementsWithNames] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [page, setPage] = useState(1);

  // Throw out any null or undefined references.
  const contentRefs = engagements?.map(e => e.content).filter(ref => ref);
  const contentMap = useContentRefs(contentRefs);

  useEffect(() => {
    const result = [];
    for (const engagement of engagements ?? []) {
      const content = contentMap[engagement.content];
      if (content) {
        const contentRepr = getBasicContentDetails(content);
        result.push({ ...engagement, ...contentRepr });
      }
    }
    setEngagementsWithNames(result);
  }, [contentMap, engagements]);

  useEffect(() => {
    if (journeys?.length === 0) dispatch(fetchAppUserJourneys(appUserId));
    if (journeys?.length > 0 && !loading && !engagements)
      dispatch(fetchAppUserEngagements(appUserId, page));
  }, [dispatch, appUserId, journeys, page, engagements, loading]);

  useEffect(() => {
    if (!loading && engagements?.length < 50) {
      setHasMoreItems(false);
    }
  }, [loading, engagements]);

  const createColumns = t => [
    {
      title: t('patients:Engagements.when'),
      dataIndex: 'time',
      key: 'time',
      sorter: (a, b) => new Date(a.time) - new Date(b.time),
      render: time => moment(time).format('L LTS'),
    },
    {
      title: t('patients:Engagements.content'),
      dataIndex: 'content_name',
      key: 'content_name',
      sorter: (a, b) => a.content_name.localeCompare(b.content_name),
    },
    {
      title: t('patients:Engagements.contentType'),
      dataIndex: 'content_type',
      key: 'content_type',
      sorter: (a, b) => a.content_type.localeCompare(b.content_type),
      render: contentType => {
        return (
          <>
            <FontAwesomeIcon
              size="lg"
              icon={selectContentTypeIcon(
                contentType.toUpperCase().endsWith('S')
                  ? contentType.toUpperCase().slice(0, -1)
                  : contentType.toUpperCase(),
              )}
            />{' '}
            {t(
              `common:contentType.${
                contentType.toUpperCase().endsWith('S')
                  ? contentType.toUpperCase().slice(0, -1)
                  : contentType.toUpperCase()
              }`,
            )}
          </>
        );
      },
    },
  ];

  const columns = useMemo(() => createColumns(t), [t]);

  const handleChangePage = page => {
    setPage(page);
    dispatch(fetchAppUserEngagements(appUserId, page));
  };

  const determineTypeForPath = type => {
    if (type.toLowerCase().includes('document')) return 'documents';
    if (type.toLowerCase().includes('form')) return 'forms';
    if (type.toLowerCase().includes('video')) return 'videos';
    if (type.toLowerCase().includes('questionnaire')) return 'questionnaires';
    if (type.toLowerCase().includes('task')) return 'tasks';
    if (type.toLowerCase().includes('weblink')) return 'weblinks';
    if (type.toLowerCase().includes('message')) return 'messages';
    if (type.toLowerCase().includes('alert')) return 'alerts';
    if (type.toLowerCase().includes('reminder')) return 'reminders';
  };

  const onRowClick = record => {
    if (
      record.content_type.toUpperCase() === MESSAGE &&
      record.content_name === t('cards:JourneyTimeline.events.adhocMessage')
    ) {
      return false;
    }
    const path = `/content/${determineTypeForPath(record.content_type)}/${record.id}`;
    history.push(path);
  };

  return (
    <ColumnGroup className="app-user-engagements">
      <Column>
        <div>
          <Card noPadding className="table-card">
            <Table
              columns={columns}
              dataSource={engagementsWithNames}
              loading={loading}
              onRowClick={onRowClick}
              rowKey="time"
              filterFunc={filterFunc}
              pagination={false}
            />
          </Card>
          <Card className="pagination-card">
            <Pagination
              current={page}
              onChange={handleChangePage}
              total={50}
              itemRender={(_, type, originalElement) => {
                if (type === 'next' && !hasMoreItems) {
                  return cloneElement(originalElement, {
                    className: 'next-button-disabled ant-pagination-next ant-pagination-item-link',
                    onClick: event => event.preventDefault(),
                  });
                }
                return originalElement;
              }}
            />
          </Card>
        </div>
      </Column>
    </ColumnGroup>
  );
}

AppUserEngagements.propTypes = {
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default AppUserEngagements;
