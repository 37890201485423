import { combineReducers } from 'redux';
import {
  ICreateWeblink,
  ICreateWeblinkFailed,
  ICreateWeblinkSuccess,
  IDeleteWeblinks,
  IDeleteWeblinksFailed,
  IDeleteWeblinksSuccess,
  IEditWeblink,
  IEditWeblinkFailed,
  IEditWeblinkSuccess,
  ILoadWeblinks,
  IWeblinksLoaded,
  IFetchWeblinkLatestVersionSuccess,
  IFetchWeblinkLatestVersionFailed,
  ILoadWeblinkLatestVersion,
} from './actions';
import { WeblinkVersion, Weblink } from '@liquid-state/ubiquity-client/dist/v2/types';
import { ILoadContentSuccess } from '@redux/core/actions';

function byId(
  state: { [key: string]: Weblink } = {},
  action:
    | IWeblinksLoaded
    | ICreateWeblinkSuccess
    | IDeleteWeblinksSuccess
    | IEditWeblinkSuccess
    | ILoadContentSuccess,
): { [key: string]: Weblink } {
  switch (action.type) {
    case 'weblinks/edit-success':
      return { ...state, [action.payload.id]: action.payload.weblink };
    case 'weblinks/create-success':
      return { ...state, [action.payload.weblink.uuid]: action.payload.weblink };
    case 'weblinks/loaded':
      return action.payload.weblinks.reduce(
        (weblinksById, weblink) => ({ ...weblinksById, [weblink.uuid]: weblink }),
        {},
      );
    case 'weblinks/delete-success':
      return Object.entries(state).reduce(
        (newState, [id, weblink]) =>
          action.payload.weblinkIds.includes(id) ? newState : { ...newState, [id]: weblink },
        {},
      );
    case 'core/load-content-success':
      return {
        ...state,
        ...action.payload.weblinks,
      };
    default:
      return state;
  }
}

function creating(
  state = false,
  action: ICreateWeblink | ICreateWeblinkFailed | ICreateWeblinkSuccess,
) {
  switch (action.type) {
    case 'weblinks/create':
      return true;
    case 'weblinks/create-failed':
    case 'weblinks/create-success':
      return false;
    default:
      return state;
  }
}

function deleting(
  state = false,
  action: IDeleteWeblinks | IDeleteWeblinksFailed | IDeleteWeblinksSuccess,
) {
  switch (action.type) {
    case 'weblinks/delete':
      return true;
    case 'weblinks/delete-failed':
    case 'weblinks/delete-success':
      return false;
    default:
      return state;
  }
}

function editing(state = false, action: IEditWeblink | IEditWeblinkFailed | IEditWeblinkSuccess) {
  switch (action.type) {
    case 'weblinks/edit':
      return true;
    case 'weblinks/edit-failed':
    case 'weblinks/edit-success':
      return false;
    default:
      return state;
  }
}

function list(
  state: string[] = [],
  action: IWeblinksLoaded | ICreateWeblinkSuccess | IDeleteWeblinksSuccess,
) {
  switch (action.type) {
    case 'weblinks/create-success':
      return [action.payload.weblink.uuid, ...state];
    case 'weblinks/loaded':
      return action.payload.weblinks.map(({ uuid }) => uuid);
    case 'weblinks/delete-success':
      return state.filter(id => !action.payload.weblinkIds.includes(id));
    default:
      return state;
  }
}

function latestVersion(
  state: { [key: string]: { loading: boolean; data?: WeblinkVersion } } = {},
  action:
    | ILoadWeblinkLatestVersion
    | IFetchWeblinkLatestVersionSuccess
    | IFetchWeblinkLatestVersionFailed
    | ICreateWeblinkSuccess
    | IEditWeblinkSuccess,
) {
  switch (action.type) {
    case 'weblinks/load-latest-version':
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] ?? {}),
          loading: true,
        },
      };

    case 'weblinks/fetch-latest-version-success':
      return {
        ...state,
        [action.payload.id]: {
          loading: false,
          data: action.payload.version,
        },
      };

    case 'weblinks/fetch-latest-version-failed':
      return {
        ...state,
        [action.payload.id]: {
          loading: false,
          data: undefined,
        },
      };

    case 'weblinks/create-success':
      return {
        ...state,
        [action.payload.weblink.uuid]: {
          loading: false,
          data: action.payload.version,
        },
      };

    case 'weblinks/edit-success':
      return {
        ...state,
        [action.payload.id]: {
          loading: false,
          data: action.payload.version,
        },
      };
    default:
      return state;
  }
}

function loading(state = false, action: ILoadWeblinks | IWeblinksLoaded) {
  switch (action.type) {
    case 'weblinks/load':
      return true;
    case 'weblinks/loaded':
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  creating,
  deleting,
  editing,
  list,
  loading,
  latestVersion,
});
