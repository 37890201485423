import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { determineSourceTypeForNewContent, extractNewDocumentVersion } from '@utils';
import useWizard from '@components/Wizard/useWizard';
import { ColumnGroup, Column } from '@cards/Card';
import DocumentDetails from '@cards/Documents/Details/Card';
import ReviewContent from './Content';

const nowMoment = moment();

function Review() {
  const permissions = useSelector(selectPermissionsForUser);
  const { formData } = useWizard();
  const { content, details } = formData;

  return (
    <ColumnGroup>
      <Column>
        <DocumentDetails
          document={{
            description: details.description,
            latest_version: {
              created: nowMoment.toISOString(),
              name: details.latestVersion ? extractNewDocumentVersion(details.latestVersion) : '1',
            },
            published: details.status === 'published',
            name: details.name,
            metadata: {
              language: details.language,
              source: determineSourceTypeForNewContent(permissions),
              audience: details.audience,
            },
          }}
          loading={false}
          documentTranslationKey="videos"
        />
      </Column>
      <Column>
        <ReviewContent content={content} />
      </Column>
    </ColumnGroup>
  );
}

export default Review;
