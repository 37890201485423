import React from 'react';
import { bool, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Icon, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import DetailRow from '@components/DetailRow';
import { whatTypeIcons, TRANSITION_NEXT, FEATURE_WEBLINK, whatLabelTypes } from '@constants';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { pathwayRuleType } from '../../propTypes';
import './style.less';

function RuleInformation({ rule }) {
  const { t } = useTranslation();
  return (
    <Form layout="vertical">
      <DetailRow
        className="rule-tooltip-row"
        label={t('pathways:ProcedureRules.ruleTooltip.name')}
        value={rule.name}
      />
      <DetailRow
        className="rule-tooltip-row"
        label={t('pathways:ProcedureRules.ruleTooltip.description')}
        value={rule.description}
      />
      <DetailRow
        className="rule-tooltip-row"
        label={t('pathways:ProcedureRules.ruleTooltip.contentTitle')}
        value={rule.whatDetail.title || rule.whatDetail.name || t('common:notApplicable')}
      />
    </Form>
  );
}

function getRuleTiming(t, rule) {
  if (rule.whenDetail.days) {
    const numberOfDays = Number(rule.whenDetail.days);
    if (numberOfDays > 0) {
      return t('cards:AppUserIndexEventTriggeredRules.timing.after', {
        numberOfDays,
        indexEventName: rule.indexEventName || '',
      });
    }

    return t('cards:AppUserIndexEventTriggeredRules.timing.before', {
      numberOfDays: Math.abs(numberOfDays),
      indexEventName: rule.indexEventName || '',
    });
  }

  return t('cards:AppUserIndexEventTriggeredRules.timing.on', {
    indexEventName: rule.indexEventName || '',
  });
}

function IndexEventTriggeredRuleIcon({ what }) {
  const { t } = useTranslation();

  if (TRANSITION_NEXT === what) {
    return (
      <Tooltip title={t(whatLabelTypes[what])}>
        <Icon component={whatTypeIcons[what]} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={t(whatLabelTypes[what])}>
      <FontAwesomeIcon icon={whatTypeIcons[what]} />
    </Tooltip>
  );
}

function RuleTag({ rule, tagClassName, triggered, isIndexEventRule, disableLink }) {
  const { t } = useTranslation();
  const history = useHistory();

  const classname = cn(tagClassName, {
    triggered,
  });

  const contentType =
    rule.what === FEATURE_WEBLINK ? rule.whatDetail.type || FEATURE_WEBLINK : rule.what;

  const RuleTagWrapper = () => (
    <div className="rule-tag-wrapper" key={rule.id}>
      <Tag className={classname} key={rule.id}>
        {isIndexEventRule ? <IndexEventTriggeredRuleIcon what={contentType} /> : null}
        {rule.audienceType === 'clinician' ? <FontAwesomeIcon icon={faStethoscope} /> : null}
        {rule.name}
      </Tag>
    </div>
  );

  return (
    <Tooltip title={isIndexEventRule ? getRuleTiming(t, rule) : <RuleInformation rule={rule} />}>
      {disableLink ? (
        <span>
          <RuleTagWrapper />
        </span>
      ) : (
        <>
          <Link to={`/procedure/rules/${rule.id}/${history.location.pathname.split('/')[3]}`}>
            <RuleTagWrapper />
          </Link>
        </>
      )}
    </Tooltip>
  );
}

RuleTag.propTypes = {
  rule: pathwayRuleType.isRequired,
  isIndexEventRule: bool,
  triggered: bool,
  tagClassName: string,
  disableLink: bool,
};

RuleTag.defaultProps = {
  isIndexEventRule: false,
  triggered: false,
  tagClassName: '',
  disableLink: false,
};

export default RuleTag;

export { IndexEventTriggeredRuleIcon };
