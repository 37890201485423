import { FEATURE_WEBLINK, whatTypeIcons } from '@constants';

function stageRulesByWhat(stages) {
  if (!stages) return [];

  const contentTypesByStage = Object.values(stages).reduce((prev, stage) => {
    return {
      ...prev,
      [stage.number]: {
        ...stage.rules.reduce((rulesByWhat, rule) => {
          const contentType =
            rule.what === FEATURE_WEBLINK ? rule.whatDetail.type || FEATURE_WEBLINK : rule.what;

          return {
            ...rulesByWhat,
            [contentType]: [...(rulesByWhat[contentType] ? rulesByWhat[contentType] : []), rule],
          };
        }, {}),
      },
    };
  }, {});

  const stageRulesByWhat = Object.entries(contentTypesByStage).reduce(
    (prev, [stageNumber, rulesByWhat]) => {
      return {
        ...prev,
        ...Object.entries(rulesByWhat).reduce(
          (stageByWhat, [whatType, rules]) => ({
            ...stageByWhat,
            [whatType]: {
              ...prev[whatType],
              icon: whatTypeIcons[whatType],
              key: `${whatType}-row`,
              [stageNumber]: rules,
            },
          }),
          {},
        ),
      };
    },
    {},
  );

  return Object.values(stageRulesByWhat);
}

export default stageRulesByWhat;
