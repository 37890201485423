import React from 'react';
import { Icon, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pathway } from '@assets/icons';
// import LanguageIcon from '@components/LanguageIcon';
import {
  DELAY,
  whatTypeIcons,
  whatLabelTypes,
  whenTypes,
  whoTypes,
  TRANSITION_NEXT,
  FEATURE_WEBLINK,
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  MESSAGE,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
  FEATURE_TASK,
  WEBSITE,
  WEB_FORM,
} from '@constants';
// import SourceIcon from '@components/SourceIcon';
// import { createLanguageFilters } from '@utils/supportedLanguages';
// import { extractSourceFromOwnerId } from '@utils';

const filters = [
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  WEBSITE,
  WEB_FORM,
  MESSAGE,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
];

export default (t, defaultFilteredValue) => [
  // {
  //   title: t('cards:ProcedureRuleList.columns.language.title'),
  //   dataIndex: 'metadata',
  //   defaultFilteredValue: defaultFilteredValue.language || [],
  //   key: 'language',
  //   render: ({ language }) => <LanguageIcon language={language} />,
  //   width: 150,
  //   sorter: (a, b) => a.metadata.language.localeCompare(b.metadata.language),
  //   filters: createLanguageFilters(t),
  //   onFilter: (value, record) => record.metadata.language === value,
  // },
  // {
  //   className: 'source-column',
  //   title: t('cards:ProcedureRuleList.columns.source.title'),
  //   dataIndex: 'ownerId',
  //   key: 'source',
  //   render: ownerId => <SourceIcon source={extractSourceFromOwnerId(ownerId)} />,
  //   width: 130,
  //   sorter: (a, b) => {
  //     if (a.source > b.source) return -1;
  //     if (a.source < b.source) return 1;
  //     return 0;
  //   },
  //   filters: [
  //     {
  //       text: t('common:source.global'),
  //       value: 'global',
  //     },
  //     {
  //       text: t('common:source.country'),
  //       value: 'country',
  //     },
  //     {
  //       text: t('common:source.hospital'),
  //       value: 'hospital',
  //     },
  //   ],
  //   onFilter: (value, record) => extractSourceFromOwnerId(record.ownerId) === value,
  // },
  {
    title: t('cards:ProcedureRuleList.columns.name'),
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      return 0;
    },
  },
  {
    title: t('cards:ProcedureRuleList.columns.what'),
    dataIndex: 'what',
    filters: filters.map(key => ({
      text: t(whatLabelTypes[key]),
      value: key,
    })),
    key: 'what',
    ellipsis: true,
    width: 100,
    onFilter: (value, record) => {
      if ([WEBSITE, WEB_FORM].includes(value)) {
        return record.whatDetail.type === value;
      }

      return value === record.what;
    },
    render: (what, rule) => {
      if (what === TRANSITION_NEXT) {
        return (
          <Tooltip title={t(whatLabelTypes[what])}>
            <Icon component={pathway} />
          </Tooltip>
        );
      }

      if (what === FEATURE_WEBLINK) {
        const whatType = rule.whatDetail.type || what;
        return (
          <Tooltip title={t(whatLabelTypes[whatType])}>
            <FontAwesomeIcon icon={whatTypeIcons[whatType]} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title={t(whatLabelTypes[what])}>
          <FontAwesomeIcon icon={whatTypeIcons[what]} />
        </Tooltip>
      );
    },
  },
  {
    title: t('cards:ProcedureRuleList.columns.who'),
    dataIndex: 'who',
    key: 'who',
    ellipsis: true,
    render: who => t(whoTypes[who]),
  },
  {
    title: t('cards:ProcedureRuleList.columns.when'),
    dataIndex: 'when',
    key: 'when',
    ellipsis: true,
    render: (when, record) => {
      if (when === DELAY) return `${record.whenDetail.days} ${t(whenTypes[when])}`;
      return t(whenTypes[when]);
    },
  },
  {
    title: t('cards:ProcedureRuleList.columns.audience'),
    dataIndex: 'audienceType',
    key: 'id',
    render: audienceType => {
      if (audienceType) return t(`cards:ProcedureRuleList.columns.${audienceType}`);
      return '';
    },
  },
];
