import React from 'react';
import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import useWizard from '@components/Wizard/useWizard';
import DetailForm from '@components/DetailForm';
import LanguageFormItem from '@components/LanguageFormItem';
import { Card } from '@cards/Card';

import './style.less';

const { Option } = Select;

const FormDetailsCard = ({ formTranslationKey, isEdit, ...props }) => {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <Card.Half
      className="new-form-details"
      title={t('forms:FormWizard.steps.details.title')}
      {...props}
    >
      <DetailForm>
        <Form.Item label={t('forms:FormWizard.steps.details.labels.title')}>
          {form.getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: t('forms:FormWizard.steps.details.feedback.titleRequired'),
              },
              {
                max: 60,
                message: t('common:Wizard.details.title.max60'),
              },
              {
                pattern: /[A-Za-z]+/,
                message: t('common:Wizard.details.title.pattern'),
              },
            ],
            initialValue: currentStepData.name,
          })(<Input placeholder={t('forms:FormWizard.steps.details.placeholders.title')} />)}
        </Form.Item>

        <Form.Item label={t('cards:FormDetail.labels.description')}>
          {form.getFieldDecorator('description', {
            rules: [],
            initialValue: currentStepData.description,
          })(
            <Input.TextArea
              placeholder={t('forms:FormWizard.steps.details.placeholders.description')}
            />,
          )}
        </Form.Item>

        <LanguageFormItem currentLanguage={currentStepData.language} form={form} />

        <Form.Item label={t('cards:FormDetail.labels.status')}>
          {form.getFieldDecorator('status', {
            rules: [
              {
                required: true,
                message: t('forms:FormWizard.steps.details.feedback.statusRequired'),
              },
            ],
            initialValue: currentStepData.status || 'publish',
          })(
            <Select>
              <Option key="publish">
                {t(
                  `forms:FormWizard.steps.details.statusOptions.${formTranslationKey}.willPublish`,
                )}
              </Option>
              <Option key="notPublish">
                {t(
                  `forms:FormWizard.steps.details.statusOptions.${formTranslationKey}.willNotPublish`,
                )}
              </Option>
            </Select>,
          )}
        </Form.Item>
        <Form.Item label={t('cards:FormDetail.labels.audience')}>
          {form.getFieldDecorator('audience', {
            rules: [
              {
                required: true,
                message: t('forms:FormWizard.steps.details.feedback.audienceRequired'),
              },
            ],
            initialValue: currentStepData.audience || 'app-user',
          })(
            <Select disabled={isEdit}>
              <Option value="app-user">
                {t(`forms:FormWizard.steps.details.audienceOptions.patient`)}
              </Option>
              <Option value="clinician">
                {t(`forms:FormWizard.steps.details.audienceOptions.clinician`)}
              </Option>
              <Option value="patient-admin">
                {t(`forms:FormWizard.steps.details.audienceOptions.patientAdmin`)}
              </Option>
            </Select>,
          )}
        </Form.Item>
      </DetailForm>
    </Card.Half>
  );
};

export default FormDetailsCard;
