export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const LOAD_DOCUMENTS = 'LOAD_DOCUMENTS';
export const DOCUMENTS_LOADED = 'DOCUMENTS_LOADED';
export const LOAD_DOCUMENTS_FAILED = 'LOAD_DOCUMENTS_FAILED';

export const FETCH_DOCUMENT_VERSIONS = 'FETCH_DOCUMENT_VERSIONS';
export const DOCUMENT_VERSIONS_LOADED = 'DOCUMENT_VERSIONS_LOADED';
export const FETCH_DOCUMENT_PAGES = 'FETCH_DOCUMENT_PAGES';
export const DOCUMENT_PAGES_LOADED = 'DOCUMENT_PAGES_LOADED';

export const GRANT_INDIVIDUAL_DOCUMENTS_ACCESS = 'GRANT_INDIVIDUAL_DOCUMENTS_ACCESS';
export const REVOKE_INDIVIDUAL_DOCUMENTS_ACCESS = 'REVOKE_INDIVIDUAL_DOCUMENTS_ACCESS';
export const GRANT_GROUP_DOCUMENTS_ACCESS = 'GRANT_GROUP_DOCUMENTS_ACCESS';
export const REVOKE_GROUP_DOCUMENTS_ACCESS = 'REVOKE_GROUP_DOCUMENTS_ACCESS';

export const PUBLISH_DOCUMENTS = 'PUBLISH_DOCUMENTS';
export const UNPUBLISH_DOCUMENTS = 'UNPUBLISH_DOCUMENTS';

export const FETCH_DOCUMENTS_BY_IDS = 'FETCH_DOCUMENTS_BY_IDS';
export const DOCUMENTS_BY_IDS_LOADED = 'DOCUMENTS_BY_IDS_LOADED';

export type ITags = {
  term: string;
  label: string;
};

export interface INewDocument {
  description: string;
  latest_version: {
    created: string;
    description: string;
    name: string;
  };
  published: boolean;
  name: string;
  metadata: {
    language: string;
    source: string;
    hospitalId?: string;
  };
  fileData: {
    uid: string;
    originFileObj: File;
  };
  audience_type: string;
}

export interface IDocument {
  name: string;
  description: string;
  id: number;
  product_id: string;
  latest_version: {
    name: string;
    description: string;
  };
  metadata: {
    tags?: ITags[];
    [key: string]: any;
  };
  published: boolean;
  audience_type: string;
}

export interface IRawDocument {
  id: number;
  name: string;
  product_id: string;
  latest_version: {
    name: string;
    description: string;
    number: number;
  };
  availability: 'unavailable' | 'free' | 'paid';
  metadata: {
    'content-provider': string;
    readonly: {
      ls: {
        carbon: {
          categories: string[];
        };
      };
    };
    custom: any;
    tags: any;
  };
  audience_type: string;
}

export interface IDocumentPage {
  latest_published_url: string;
  name: string;
  source_page_number: number;
}

export interface INewVideo {
  description: string;
  latest_version: {
    created: string;
    name: string;
  };
  published: boolean;
  name: string;
  metadata: {
    language: string;
    source: string;
  };
  videoData: {
    videoTitle: string;
    videoDescription: string;
    videoId: string;
  };
  audience_type: string;
}

export interface IDocumentVersion {}
