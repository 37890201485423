import {
  FETCH_DOCUMENTS,
  LOAD_DOCUMENTS,
  DOCUMENTS_LOADED,
  LOAD_DOCUMENTS_FAILED,
  FETCH_DOCUMENT_VERSIONS,
  DOCUMENT_VERSIONS_LOADED,
  FETCH_DOCUMENT_PAGES,
  DOCUMENT_PAGES_LOADED,
  GRANT_INDIVIDUAL_DOCUMENTS_ACCESS,
  REVOKE_INDIVIDUAL_DOCUMENTS_ACCESS,
  GRANT_GROUP_DOCUMENTS_ACCESS,
  REVOKE_GROUP_DOCUMENTS_ACCESS,
  PUBLISH_DOCUMENTS,
  UNPUBLISH_DOCUMENTS,
  FETCH_DOCUMENTS_BY_IDS,
  DOCUMENTS_BY_IDS_LOADED,
  IDocument,
  IDocumentPage,
  INewDocument,
  INewVideo,
} from './types';
import { IReduxAction } from '../types';

export interface IFetchDocuments extends IReduxAction<typeof FETCH_DOCUMENTS> {}
export const fetchDocuments = (): IFetchDocuments => ({
  type: FETCH_DOCUMENTS,
  payload: {},
});

// create action for fetching documents by a list of ids using ubiquity api
// do this for the other content types

export interface IFetchDocumentsByIds
  extends IReduxAction<typeof FETCH_DOCUMENTS_BY_IDS, { documentIds: string[] }> {}
export const fetchDocumentsByIds = (documentIds: string[]): IFetchDocumentsByIds => ({
  type: FETCH_DOCUMENTS_BY_IDS,
  payload: { documentIds },
});

export interface IDocumentsByIdsLoaded
  extends IReduxAction<
    typeof DOCUMENTS_BY_IDS_LOADED,
    { documents: { [key: string]: IDocument } }
  > {}
export const documentsByIdsLoaded = (documents: {
  [key: string]: IDocument;
}): IDocumentsByIdsLoaded => ({
  type: DOCUMENTS_BY_IDS_LOADED,
  payload: { documents },
});

export interface ILoadDocuments extends IReduxAction<typeof LOAD_DOCUMENTS> {}
export const loadDocuments = (): ILoadDocuments => ({
  type: LOAD_DOCUMENTS,
  payload: {},
});

export interface IDocumentsLoaded
  extends IReduxAction<typeof DOCUMENTS_LOADED, { documents: IDocument[] }> {}
export const documentsLoaded = (documents: IDocument[]): IDocumentsLoaded => ({
  type: DOCUMENTS_LOADED,
  payload: { documents },
});

export interface ILoadDocumentsFailed
  extends IReduxAction<typeof LOAD_DOCUMENTS_FAILED, { documentTranslationKey: string }> {}
export const loadDocumentsFailed = (documentTranslationKey: string) => ({
  type: LOAD_DOCUMENTS_FAILED,
  payload: {
    documentTranslationKey,
  },
});

export interface IFetchDocumentVersions
  extends IReduxAction<typeof FETCH_DOCUMENT_VERSIONS, { documentId: string; force: boolean }> {}
export const fetchDocumentVersions = (
  documentId: string,
  force: boolean = false,
): IFetchDocumentVersions => ({
  type: FETCH_DOCUMENT_VERSIONS,
  payload: { documentId, force },
});

export interface IDocumentVersionsLoaded
  extends IReduxAction<
    typeof DOCUMENT_VERSIONS_LOADED,
    { documentId: string; versions: string[] }
  > {}
export const documentVersionsLoaded = (
  documentId: string,
  versions: string[],
): IDocumentVersionsLoaded => ({
  type: DOCUMENT_VERSIONS_LOADED,
  payload: { documentId, versions },
});

export interface IFetchDocumentPages
  extends IReduxAction<
    typeof FETCH_DOCUMENT_PAGES,
    { documentId: string; versionId: string; force: boolean }
  > {}
export const fetchDocumentPages = (
  documentId: string,
  versionId: string,
  force = false,
): IFetchDocumentPages => ({
  type: FETCH_DOCUMENT_PAGES,
  payload: { documentId, versionId, force },
});

export interface IDocumentPagesLoaded
  extends IReduxAction<
    typeof DOCUMENT_PAGES_LOADED,
    { documentId: string; pages: IDocumentPage[] }
  > {}
export const documentPagesLoaded = (
  documentId: string,
  pages: IDocumentPage[],
): IDocumentPagesLoaded => ({
  type: DOCUMENT_PAGES_LOADED,
  payload: { documentId, pages },
});

export interface IGrantDocumentsGroupAccess
  extends IReduxAction<
    typeof GRANT_GROUP_DOCUMENTS_ACCESS,
    { documentIds: string[]; groupIds: string[] }
  > {}
export const grantDocumentsGroupAccess = (
  documentIds: string[],
  groupIds: string[],
): IGrantDocumentsGroupAccess => ({
  type: GRANT_GROUP_DOCUMENTS_ACCESS,
  payload: {
    documentIds,
    groupIds,
  },
});

export interface IRevokeDocumentsGroupAccess
  extends IReduxAction<
    typeof REVOKE_GROUP_DOCUMENTS_ACCESS,
    { documentIds: string[]; groupIds: string[] }
  > {}
export const revokeDocumentsGroupAccess = (
  documentIds: string[],
  groupIds: string[],
): IRevokeDocumentsGroupAccess => ({
  type: REVOKE_GROUP_DOCUMENTS_ACCESS,
  payload: {
    documentIds,
    groupIds,
  },
});

export interface IGrantDocumentsIndividualAccess
  extends IReduxAction<
    typeof GRANT_INDIVIDUAL_DOCUMENTS_ACCESS,
    { documentIds: string[]; appUserIds: string[] }
  > {}
export const grantDocumentsIndividualAccess = (
  documentIds: string[],
  appUserIds: string[],
): IGrantDocumentsIndividualAccess => ({
  type: GRANT_INDIVIDUAL_DOCUMENTS_ACCESS,
  payload: {
    documentIds,
    appUserIds,
  },
});

export interface IRevokeDocumentsIndividualAccess
  extends IReduxAction<
    typeof REVOKE_INDIVIDUAL_DOCUMENTS_ACCESS,
    { documentIds: string[]; appUserIds: string[] }
  > {}
export const revokeDocumentsIndividualAccess = (
  documentIds: string[],
  appUserIds: string[],
): IRevokeDocumentsIndividualAccess => ({
  type: REVOKE_INDIVIDUAL_DOCUMENTS_ACCESS,
  payload: {
    documentIds,
    appUserIds,
  },
});

export interface IPublishDocuments
  extends IReduxAction<typeof PUBLISH_DOCUMENTS, { documentIds: number[] }> {}
export const publishDocuments = (documentIds: number[]): IPublishDocuments => ({
  type: PUBLISH_DOCUMENTS,
  payload: { documentIds },
});

export type IPublishDocumentsSuccess = ReturnType<typeof publishDocumentsSuccess>;
export const publishDocumentsSuccess = (documentIds: number[]) => ({
  type: 'documents/publishDocumentsSuccess' as const,
  payload: { documentIds },
});

export type IPublishDocumentsFailed = ReturnType<typeof publishDocumentsFailed>;
export const publishDocumentsFailed = () => ({
  type: 'documents/publishDocumentsFailed' as const,
  payload: {},
});

export interface IUnpublishDocuments
  extends IReduxAction<typeof UNPUBLISH_DOCUMENTS, { documentIds: number[] }> {}
export const unpublishDocuments = (documentIds: number[]): IUnpublishDocuments => ({
  type: UNPUBLISH_DOCUMENTS,
  payload: { documentIds },
});

export type IUnpublishDocumentsSuccess = ReturnType<typeof unpublishDocumentsSuccess>;
export const unpublishDocumentsSuccess = (documentIds: number[]) => ({
  type: 'documents/unpublishDocumentsSuccess' as const,
  payload: { documentIds },
});

export type IUnpublishDocumentsFailed = ReturnType<typeof unpublishDocumentsFailed>;
export const unpublishDocumentsFailed = () => ({
  type: 'documents/unpublishDocumentsFailed' as const,
  payload: {},
});

export type IChangePublishedStatus = ReturnType<typeof changePublishedStatus>;
export const changePublishedStatus = (documentId: number, isPublished: boolean) => ({
  type: 'documents/changePublishStatus' as const,
  payload: { documentId, isPublished },
});

export type ICreateDocument = ReturnType<typeof createDocument>;
export const createDocument = (newDocument: INewDocument) => ({
  type: 'documents/create' as const,
  payload: { newDocument },
});

export type ICreateDocumentFailed = ReturnType<typeof createDocumentFailed>;
export const createDocumentFailed = () => ({
  type: 'documents/createFailed' as const,
  payload: {},
});

export type ICreateDocumentSuccess = ReturnType<typeof createDocumentSuccess>;
export const createDocumentSuccess = (createdDocument: IDocument) => ({
  type: 'documents/createSuccess' as const,
  payload: { createdDocument },
});

export type IEditDocument = ReturnType<typeof editDocument>;
export const editDocument = (documentId: number, editedDocument: INewDocument) => ({
  type: 'documents/edit' as const,
  payload: { documentId, editedDocument },
});

export type IEditDocumentSuccess = ReturnType<typeof editDocumentSuccess>;
export const editDocumentSuccess = (documentId: number, editedDocument: IDocument) => ({
  type: 'documents/editSuccess' as const,
  payload: { documentId, editedDocument },
});

export type IEditDocumentFailed = ReturnType<typeof editDocumentFailed>;
export const editDocumentFailed = () => ({
  type: 'documents/editFailed' as const,
  payload: {},
});

export type IDeleteDocuments = ReturnType<typeof deleteDocuments>;
export const deleteDocuments = (documentIds: number[], documentTranslationKey: string) => ({
  type: 'documents/delete' as const,
  payload: {
    documentIds,
    documentTranslationKey,
  },
});

export type IDeleteDocument = ReturnType<typeof deleteDocument>;
export const deleteDocument = (documentId: number, documentTranslationKey: string) => ({
  type: 'documents/deleteOne' as const,
  payload: { documentId, documentTranslationKey },
});

export type IDeleteDocumentsSuccess = ReturnType<typeof deleteDocumentsSuccess>;
export const deleteDocumentsSuccess = (documentIds: number[]) => ({
  type: 'documents/deleteSuccess' as const,
  payload: { documentIds },
});

export type IDeleteDocumentsFailed = ReturnType<typeof deleteDocumentsFailed>;
export const deleteDocumentsFailed = () => ({
  type: 'documents/deleteFailed' as const,
  payload: {},
});

//video creation

export type ICreateVideo = ReturnType<typeof createVideo>;
export const createVideo = (newDocument: INewVideo) => ({
  type: 'videos/create' as const,
  payload: { newDocument },
});

export type ICreateVideoFailed = ReturnType<typeof createVideoFailed>;
export const createVideoFailed = () => ({
  type: 'videos/createFailed' as const,
  payload: {},
});

// the video Document will be the data object that is returned after calling ubiquity.Underlying reducer at reducers file has been commented out. Uncomment once it is ready.(reducer assumes data object is the same as that when document is edited/created.Change if necessary)
export type ICreateVideoSuccess = ReturnType<typeof createVideoSuccess>;
export const createVideoSuccess = (createdDocument: IDocument) => ({
  type: 'videos/createSuccess' as const,
  payload: { createdDocument },
});

export type IEditVideo = ReturnType<typeof editVideo>;
export const editVideo = (
  ceDocumentUUID: string,
  ubiquityDocumentId: number,
  editedDocument: INewVideo,
) => ({
  type: 'videos/edit' as const,
  payload: { ceDocumentUUID, ubiquityDocumentId, editedDocument },
});

export type IEditVideoSuccess = ReturnType<typeof editVideoSuccess>;
// the video Document will be the data object that is returned after calling ubiquity.Underlying reducer at reducers file has been commented out. Uncomment once it is ready.(reducer assumes data object is the same as that when document is edited/created.Change if necessary)
export const editVideoSuccess = (documentId: number, editedDocument: IDocument) => ({
  type: 'videos/editSuccess' as const,
  payload: { documentId, editedDocument },
});

export type IEditVideoFailed = ReturnType<typeof editVideoFailed>;
export const editVideoFailed = () => ({
  type: 'videos/editFailed' as const,
  payload: {},
});

export type IGetDocumentPreview = ReturnType<typeof getDocumentPreview>;
export const getDocumentPreview = (documentId: string) => ({
  type: 'documents/get-preview' as const,
  payload: { documentId },
});

export type IClearDocumentPreview = ReturnType<typeof clearDocumentPreview>;
export const clearDocumentPreview = () => ({
  type: 'document/clear-preview' as const,
  payload: {},
});

export type IDocumentPreviewLoaded = ReturnType<typeof documentPreviewLoaded>;
export const documentPreviewLoaded = (pages: string[]) => ({
  type: 'documents/preview-loaded' as const,
  payload: { pages },
});

export type IDocumentPreviewFailed = ReturnType<typeof documentPreviewFailed>;
export const documentPreviewFailed = () => ({
  type: 'documents/preview-failed' as const,
  payload: {},
});
