import { put, call, takeEvery, takeLatest, getContext, select } from 'redux-saga/effects';
import { message } from 'antd';
import { selectAppDetails } from '@organisation/redux/selectors';
import { doCreateUbiquityV2Client } from '@redux/doCreateUbiquityClient';
import i18n from '../../i18n';
import {
  loadContentMessages,
  contentMessagesLoaded,
  ICreateContentMessage,
  IUpdateContentMessage,
  createContentMessageSuccess,
  createContentMessageFailed,
  updateContentMessageSuccess,
  updateContentMessageFailed,
  deleteContentMessagesSuccess,
  deleteContentMessagesFailed,
  IDeleteContentMessages,
  publishContentMessageFailed,
  publishContentMessageSuccess,
  unpublishContentMessageFailed,
  unpublishContentMessageSuccess,
  IPublishContentMessage,
  IUnpublishContentMessage,
} from './actions';
import { IContentMessage, IRawContentMessage } from './types';
import takeFirst from '../takeFirst';
import { mapRawContentMessageToContentMessage } from './utils';
import { trackDataEvent } from '../../analytics';
import { selectContentMessage } from './selectors';
import { selectOrganisationSlug } from '@organisation/redux/selectors';
import ContentRef from '@utils/contentRef';
import { getUnderlyingContentType } from '@constants';

export default function* root() {
  yield takeFirst('content-messages/fetch', doFetchContentMessages);
  yield takeLatest('content-messages/create', doCreateContentMessage);
  yield takeLatest('content-messages/update', doUpdateContentMessage);
  yield takeLatest('content-messages/delete', doDeleteContentMessages);
  yield takeEvery('content-messages/publish', doPublishContentMessage);
  yield takeEvery('content-messages/unpublish', doUnpublishContentMessage);
}

function* doFetchContentMessages(): any {
  try {
    yield put(loadContentMessages());
    const { appToken, organisationSlug } = yield select(selectAppDetails);
    const client = (yield call(doCreateUbiquityV2Client)).messages(appToken);
    const pagedClient = client.pagination().begin(client.list());

    let results: IContentMessage[] = [];

    while (pagedClient.hasNext()) {
      const nextData = yield call(pagedClient.next);
      results = [
        ...results,
        ...nextData
          .map((rawMessage: IRawContentMessage) =>
            mapRawContentMessageToContentMessage(rawMessage, organisationSlug),
          )
          .filter((r: any) => r),
      ];
    }
    yield put(contentMessagesLoaded(results));
  } catch (err) {
    console.error(err);
    yield call(message.warning, 'Failed to load messages');
  }
}

function* doCreateContentMessage({
  payload: { message: newMessage, type, messageTranslationKey, path },
}: ICreateContentMessage): any {
  try {
    const { appToken } = yield select(selectAppDetails);
    const organisationId = yield select(selectOrganisationSlug());
    const client = (yield call(doCreateUbiquityV2Client)).messages(appToken);
    const contentMessage = yield call(client.create, { name: newMessage.name });

    // add Content Ref under payload.data.content property
    const payload: { data: { metadata: any; content?: string } } = newMessage.payload as {
      data: { metadata: any };
    };
    if (payload?.data?.metadata) {
      const dashboardContentType: string | undefined = payload.data.metadata?.type;
      const contentType = dashboardContentType
        ? getUnderlyingContentType(dashboardContentType)
        : undefined;
      const contentId = ContentRef.guessContentId(payload.data);
      if (!contentType || !contentId) {
        console.error(`Could not create Content Ref for Message payload data: ${payload}`);
        yield call(
          message.warning,
          i18n.t(`${messageTranslationKey}:NewContentMessageWizard.failedMessage`),
        );
        yield put(updateContentMessageFailed());
        return;
      }
      const contentRef = new ContentRef(organisationId, contentType, contentId);
      payload.data.content = contentRef.toString();
    }

    const version = yield call(client.createVersion, contentMessage, {
      body: newMessage.body,
      metadata: { ...newMessage.metadata, language: newMessage.language },
      payload: newMessage.payload,
      title: newMessage.name,
    });

    yield call(client.packageVersion, version, contentMessage);

    if (newMessage.published) {
      yield call(client.makeAvailable, contentMessage);
    }

    yield call(
      message.success,
      i18n.t(`${messageTranslationKey}:NewContentMessageWizard.successMessage`),
    );

    const createdMessage = mapRawContentMessageToContentMessage(
      {
        ...contentMessage,
        latest_version: version,
        is_available: newMessage.published || false,
      },
      organisationId,
    );
    if (!createdMessage) {
      throw new Error('Invalid Message');
    }

    trackDataEvent('Content Message Created', {
      messageId: createdMessage.id,
      messageTitle: createdMessage.name,
      messageType: type,
    });

    yield put(createContentMessageSuccess(createdMessage));

    const history = yield getContext('history');
    const newPath = path.replace('new', contentMessage.uuid);

    yield call(history.replace, newPath);
  } catch (err) {
    console.error('create error', err);
    yield call(
      message.warning,
      i18n.t(`${messageTranslationKey}:NewContentMessageWizard.failedMessage`),
    );
    yield put(createContentMessageFailed());
  }
}

function* doUpdateContentMessage({
  payload: { message: updatedMessage, type, messageTranslationKey, id },
}: IUpdateContentMessage): any {
  try {
    const { appToken } = yield select(selectAppDetails);
    const client = (yield call(doCreateUbiquityV2Client)).messages(appToken);
    const [, currentMessage] = yield select(selectContentMessage(updatedMessage.id));
    const organisationId = yield select(selectOrganisationSlug());

    const ubiquityMessage = yield call(client.edit, currentMessage, { name: updatedMessage.name });

    // add Content Ref under payload.data.content property
    const payload: { data: { metadata: any; content?: string } } = updatedMessage.payload as {
      data: { metadata: any };
    };
    if (payload?.data?.metadata) {
      const dashboardContentType: string | undefined = payload.data.metadata?.type;
      const contentType = dashboardContentType
        ? getUnderlyingContentType(dashboardContentType)
        : undefined;
      const contentId = ContentRef.guessContentId(payload.data);
      if (!contentType || !contentId) {
        console.error(`Could not create Content Ref for Message payload data: ${payload}`);
        yield call(
          message.warning,
          i18n.t(`${messageTranslationKey}:EditContentMessageWizard.failedMessage`),
        );
        yield put(updateContentMessageFailed());
        return;
      }
      const contentRef = new ContentRef(organisationId, contentType, contentId);
      payload.data.content = contentRef.toString();
    }

    const version = yield call(client.createVersion, ubiquityMessage, {
      body: updatedMessage.body,
      metadata: { ...updatedMessage.metadata, language: updatedMessage.language },
      payload: payload,
      title: updatedMessage.name,
    });

    yield call(client.packageVersion, version, ubiquityMessage);

    const editedMessage = mapRawContentMessageToContentMessage(
      {
        ...ubiquityMessage,
        latest_version: version,
        is_available: updatedMessage.published || false,
      },
      organisationId,
    );
    if (!editedMessage) {
      throw new Error('Invalid Message');
    }

    if (updatedMessage.published === true && currentMessage.published === false) {
      yield call(client.makeAvailable, ubiquityMessage);
    } else if (updatedMessage.published === false && currentMessage.published === true) {
      yield call(client.makeUnavailable, ubiquityMessage);
    }

    yield call(
      message.success,
      i18n.t(`${messageTranslationKey}:EditContentMessageWizard.successMessage`),
    );
    trackDataEvent('Content Message Updated', {
      messageId: updatedMessage.id,
      messageTitle: updatedMessage.name,
      messageType: type,
    });
    yield put(updateContentMessageSuccess(editedMessage));

    const history = yield getContext('history');
    yield call(history.goBack);
  } catch (err) {
    console.error(err);
    yield call(
      message.warning,
      i18n.t(`${messageTranslationKey}:EditContentMessageWizard.failedMessage`),
    );
    yield put(updateContentMessageFailed());
  }
}

function* doDeleteContentMessages({
  payload: { messageIds, messageTranslationKey },
}: IDeleteContentMessages): any {
  try {
    const { appToken } = yield select(selectAppDetails);
    const client = (yield call(doCreateUbiquityV2Client)).messages(appToken);

    for (const id of messageIds) {
      const [, message] = yield select(selectContentMessage(id));

      yield call(client.delete, message);
    }

    yield put(deleteContentMessagesSuccess(messageIds));

    yield call(
      message.success,
      i18n.t(`${messageTranslationKey}:DeleteMessage.deleteSuccess`, { count: messageIds.length }),
    );
  } catch (err) {
    console.error(err);
    yield call(
      message.warning,
      i18n.t(`${messageTranslationKey}:DeleteMessage.deleteFailed`, { count: messageIds.length }),
    );
    yield put(deleteContentMessagesFailed());
  }
}

function* doPublishContentMessage({
  payload: { id, translationKey },
}: IPublishContentMessage): any {
  try {
    const { appToken } = yield select(selectAppDetails);
    const client = (yield call(doCreateUbiquityV2Client)).messages(appToken);
    const [, contentMessage] = yield select(selectContentMessage(id));

    yield call(client.makeAvailable, contentMessage);

    yield put(publishContentMessageSuccess(id));
  } catch (err) {
    console.error(err);
    yield put(publishContentMessageFailed());
  }
}

function* doUnpublishContentMessage({
  payload: { id, translationKey },
}: IUnpublishContentMessage): any {
  try {
    const { appToken } = yield select(selectAppDetails);
    const client = (yield call(doCreateUbiquityV2Client)).messages(appToken);
    const [, contentMessage] = yield select(selectContentMessage(id));

    yield call(client.makeUnavailable, contentMessage);

    yield put(unpublishContentMessageSuccess(id));
  } catch (err) {
    console.error(err);
    yield put(unpublishContentMessageFailed());
  }
}
