import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  faTrashAlt,
  // faCommentAltLines
} from '@fortawesome/pro-regular-svg-icons';
import {
  // Divider,
  Modal,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { FEATURE_FORM, FEATURE_QUESTIONNAIRE, FEATURE_TASK } from '@constants';
import { Column, ColumnGroup } from '@cards/Card';
import FormDetails from '@cards/Forms/FormDetails';
import FormContent from '@cards/Forms/FormContent';
import FormDataDownload from '@cards/Forms/FormDataDownload';
import FormPathways from '@pathways/cards/FormPathways';
import PublishingButton from '@components/PublishingButton';
import ContentNotFound from '@components/ContentNotFound';
import { usePageActions } from '@hooks';
import { fetchForms, deleteForms, publishForm, unpublishForm } from '@redux/forms/actions';
import { selectForm, selectFormPublishing } from '@redux/forms/reducers';
import { selectPathwaysByRules } from '@pathways/redux/pathways/reducers';
import IconButton from '@components/IconButton';
import EditButton from '@components/EditButton';
import { envHasDataLake } from 'settings';
import useIsContentOwnedByCurrentOrganisation from '@hooks/useIsContentOwnedByCurrentOrganisation';

export default ({
  match: {
    params: { id },
  },
  formTranslationKey,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { t } = useTranslation();

  const [loading, form] = useSelector(selectForm(id));
  const [, pathways] = useSelector(
    selectPathwaysByRules(
      rule =>
        (rule.what === FEATURE_FORM ||
          rule.what === FEATURE_QUESTIONNAIRE ||
          rule.what === FEATURE_TASK) &&
        rule.whatDetail.uuid === id,
    ),
  );
  const isPublished = form ? form.published : true;
  const isPublishing = useSelector(selectFormPublishing);

  const isOwnedContent = useIsContentOwnedByCurrentOrganisation(form);

  const PublishingAction = useMemo(
    () =>
      isPublished === undefined ? null : (
        <PublishingButton
          key="toggle-published-status"
          isPublished={isPublished}
          isPublishing={isPublishing}
          publish={() => dispatch(publishForm(id, formTranslationKey))}
          unpublish={() => dispatch(unpublishForm(id, formTranslationKey))}
          disabled={!isOwnedContent}
        />
      ),
    [dispatch, formTranslationKey, id, isOwnedContent, isPublished, isPublishing],
  );

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch]);

  const { setTitle, setActions } = usePageActions({
    showBack: true,
  });

  useEffect(() => {
    document.title = form && form.name;
    setTitle(form ? form.name : undefined);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActions([
      // <IconButton
      //   key="send-message"
      //   tooltip={t('common:buttons.sendMessage')}
      //   icon={faCommentAltLines}
      //   size="large"
      //   disabled
      // />,
      // <Divider key="divider" type="vertical" />,
      PublishingAction,
      <IconButton
        key="delete-btn"
        tooltip={t('common:buttons.delete')}
        icon={faTrashAlt}
        size="large"
        disabled={!isOwnedContent}
        onClick={() => {
          Modal.confirm({
            content: !!pathways.length
              ? t(`${formTranslationKey}:FormDetail.confirmDeleteWarning`, {
                  count: pathways.length,
                })
              : null,
            title: t(`${formTranslationKey}:FormDetail.confirmDelete`),
            onOk: () => {
              history.goBack();
              dispatch(deleteForms([id], formTranslationKey));
              return;
            },
            onCancel: () => {},
          });
        }}
      />,
      <EditButton
        key="edit-btn"
        tooltip={t(`${formTranslationKey}:FormDetail.editButton`)}
        onClick={() => history.push(`edit/${id}`)}
        disabled={!isOwnedContent}
      />,
    ]);
  }, [
    PublishingAction,
    history,
    setActions,
    t,
    formTranslationKey,
    dispatch,
    id,
    pathways.length,
    isOwnedContent,
  ]);

  if (!form && !loading) {
    return <ContentNotFound />;
  }

  return (
    <ColumnGroup>
      <Column>
        <FormDetails formId={id} formTranslationKey={formTranslationKey} />
        <FormPathways formId={id} />
      </Column>
      <Column>
        <FormContent formId={id} canDownload canPreview />
        {envHasDataLake ? <FormDataDownload formId={id} canDownload canPreview /> : null}
      </Column>
    </ColumnGroup>
  );
};
