import React, { useCallback, useMemo, useState } from 'react';
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types';
import { Table, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { antTableColumnType } from '../../propTypes';
import { useSearch } from '@hooks';
import { Card } from '../../cards/Card';
import useFilters from '@hooks/useFilters';
import './style.less';

function SearchableTable({
  className,
  columns,
  dataSource,
  extra,
  hasRoutedPagination,
  loading,
  rowKey,
  scroll,
  title,
  filterFunc,
  pagination,
  onRowClick,
  onRowSelectionChange,
  rowClassName,
  placeholder,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [, setFilters] = useFilters();
  const [{ page, search }, setQueryStringParams] = useState(
    queryString.parse(history.location.search),
  );
  const [searchResults, searchProps] = useSearch(dataSource, filterFunc, 1, search);

  const updateSearchParameters = useCallback(
    newParameters => {
      const currentParameters = queryString.parse(history.location.search);
      const updated = { ...currentParameters, ...newParameters };
      setQueryStringParams(updated);
      history.push(`${match.path}?${queryString.stringify(updated)}`);
    },
    [history, match.path],
  );

  const paginationProps = useMemo(() => {
    if (!pagination) return false;

    const props = { position: 'bottom' };

    if (hasRoutedPagination) {
      props.current = Number(page);
      props.onChange = pageNumber => {
        updateSearchParameters({ page: Number(pageNumber) });
      };
    }

    return props;
  }, [hasRoutedPagination, page, pagination, updateSearchParameters]);

  const handleFilterChange = (_, filters) => {
    setFilters(filters);
  };

  return (
    <Card.Full className={`searchable-table-card ${className}`} noPadding title={title}>
      <div className="search-container">
        <Input.Search
          placeholder={placeholder ? placeholder : t('common:search')}
          onBlur={e => updateSearchParameters({ search: e.target.value || undefined })}
          {...searchProps}
        />
        <div className="extra">{extra}</div>
      </div>
      <Table
        columns={columns}
        dataSource={searchResults}
        loading={loading}
        pagination={paginationProps}
        rowKey={rowKey}
        rowSelection={onRowSelectionChange ? { onChange: onRowSelectionChange } : null}
        scroll={scroll || { x: 1000 }}
        onRow={row => ({
          onClick: onRowClick
            ? (...args) => onRowClick(row, ...args)
            : () => history.push(`${match.path}/${row[rowKey]}`),
        })}
        onChange={handleFilterChange}
        rowClassName={rowClassName}
      />
    </Card.Full>
  );
}

SearchableTable.propTypes = {
  className: string,
  columns: arrayOf(antTableColumnType).isRequired,
  dataSource: arrayOf(shape({})).isRequired,
  hasRoutedPagination: bool,
  loading: bool,
  pagination: bool,
  rowKey: string,
  scroll: shape({}),
  title: string,
  filterFunc: func.isRequired,
  onRowClick: func,
  onRowSelectionChange: oneOfType([bool, func]),
  rowClassName: func,
  placeholder: string,
};

SearchableTable.defaultProps = {
  className: '',
  hasRoutedPagination: false,
  loading: false,
  pagination: true,
  scroll: null,
  title: null,
  onRowSelectionChange: null,
  rowKey: 'id',
  rowClassName: () => '',
  placeholder: '',
};

export default SearchableTable;
